import React, { useState, useEffect, useRef } from "react";
import { QIDropdown, QIModal, QIModalBody, QIModalFooter, QIButton } from "../../../components";
import { useNavigate } from "react-router-dom";
import { SvgIcon } from "../../Shared";
import { FMDPStorage } from "../../../shared/helper";
import { toggleAppInfo } from "../../Authentication/helper";
import { tHelper } from "../../../shared/translation";
import { smallLogo, userPlaceholder } from "../../../assets/";
import {
  useCreateAuthMutation,
  useSelectClientAppMutation,
  useSelectSuperAdminMutation,
  useLogoutAppMutation,
} from "../../Authentication/service";
import {
  useGetLanguagesQuery,
  useGetLocalizationsQuery,
  useUpdateUserProfileMutation,
  useChangeLayoutMutation,
  useGetLayoutsQuery,
} from "../../ManagementPortal/services";
import { Scrollbars } from "react-custom-scrollbars";
import { useDispatch } from "react-redux";
import { setFlashScreen } from "../../../reduxStore/features";
import { getLocalizedString } from "../../../shared/translation";
import { useDropDownSearch } from "../../../hooks";

export const CommonHeader = ({ appName, appToToggle, setLayoutSelectedAppId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentLanguage, setCurrentLanguage] = useState(tHelper.currentLanguage);
  const [selectedAppId, setSelectedAppId] = useState(FMDPStorage.get("selected-app-id"));
  const [appToBeSelected, setAppToBeSelected] = useState("");
  const [dropDownSection, setDropDownSection] = useState("");
  const [quickDashboard, setQuickDashBoard] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [gridSelectedApp, setGridSelectedApp] = useState("");
  const [showConfirmationModal, setshowConfirmationModal] = useState();

  const dashboardRef = useRef(null);

  const [
    selectClientApp,
    {
      isSuccess: selectClientAppSuccess,
      data: clientAppData,
      reset: resetSelectClientApp,
      error: selectClientAppError,
    },
  ] = useSelectClientAppMutation({
    fixedCacheKey: "shared-client-app-data",
  });

  const [
    selectSuperAdmin,
    {
      isSuccess: selectSuperAdminSuccess,
      reset: resetSelectSuperAdmin,
      error: selectSuperAdminError,
    },
  ] = useSelectSuperAdminMutation({
    fixedCacheKey: "shared-superadmin-app-data",
  });

  const [, { data: user }] = useCreateAuthMutation({
    fixedCacheKey: "shared-sso-data",
  });

  const [logoutApp, { isSuccess: logoutAppSuccess, reset: resetlogoutApp, error: logoutAppError }] =
    useLogoutAppMutation();

  const { data: languages } = useGetLanguagesQuery({ per_page: 1000, order_by: "name" });

  const currentUserDetails = FMDPStorage.get("current-user");
  const selectedClientId = FMDPStorage.get("selected-client-id");
  const layout = currentUserDetails?.layout === "Default" ? false : true;
  const [isAlternativeUI, setIsAlternativeUI] = useState();

  useEffect(() => {
    setIsAlternativeUI(layout);
  }, [layout]);

  // Get the user config json and the localization profile
  let userConfigJson = "";
  let LocalizationProfile = "";
  currentUserDetails?.client_apps?.map((user) => {
    if (user?.id === selectedAppId) {
      userConfigJson = user?.client?.config_json;
    }
  });

  try {
    LocalizationProfile = JSON.parse(userConfigJson)?.tracking?.web?.localization_profile;
  } catch {}

  const {
    data: localizationData,
    isSuccess: isLocalizationSuccess,
    isLoading: isLocalizationLoading,
    error: localizationError,
  } = useGetLocalizationsQuery({
    language: currentUserDetails?.locale?.code,
    profile: LocalizationProfile || "Tracksynq Web",
    per_page: 1000,
  });

  // Get the layouts list
  const { data: layouts, isSuccess: isLayoutsSuccess } = useDropDownSearch({
    useData: useGetLayoutsQuery,
    simpleSearchKey: "name_cont",
  });

  const [transformedData, setTransformedData] = useState({});

  useEffect(() => {
    const result = {};
    if (localizationData?.data) {
      localizationData?.data?.forEach((item) => {
        result[item.key] = item.value;
      });
      setTransformedData(JSON.stringify(result, null, 2));
    }
  }, [localizationData]);

  useEffect(() => {
    if (transformedData) {
      tHelper.setLocalizations(transformedData);
    }
  }, [transformedData]);

  const [update, { error: updateError, isSuccess: updateSuccess }] = useUpdateUserProfileMutation();
  const [updateLayout, { error: layoutError, isSuccess: isSuccessLayout }] =
    useChangeLayoutMutation();

  useEffect(() => {
    if (languages) {
      tHelper.setSupportedLanguage(languages);
    }
  }, [languages]);

  const clientName = localStorage.getItem("selected-client") || "";

  const logOut = () => {
    logoutApp();
  };

  useEffect(() => {
    if (logoutAppSuccess) {
      FMDPStorage.clear();
      window.location.reload();
    }
  }, [logoutAppSuccess]);

  const switchApplication = () => {
    if (user.superadmin && selectedAppId !== appToBeSelected && appToBeSelected == "superadmin") {
      selectSuperAdmin();
    } else if (appToBeSelected !== selectedAppId) {
      selectClientApp({ client_app_id: appToBeSelected });
    }
  };

  const updateUser = (data) => {
    const { first_name, last_name, picture, locale_id } = data;
    const formData = { first_name, last_name, picture, locale_id };

    if (!formData.picture || !formData.picture.image_extension) {
      formData.picture = { image_extension: "", image_data: "", _destroy: true };
    }

    const currentUser = FMDPStorage.get("current-user");
    update({ formData, id: currentUser.id });
  };

  // Navigate to app and toggle app info once app is changed
  useEffect(() => {
    if (selectClientAppSuccess) {
      if (toggleAppInfo(user, appToBeSelected || appToToggle, clientAppData)) {
        setSelectedAppId(appToBeSelected || appToToggle);
        //Remove Modal;
        setAppToBeSelected("");
        navigate("/");
      }
      resetSelectClientApp();
    }
  }, [selectClientAppSuccess]);

  // Navigate to admin dashboard if selected as super admin
  useEffect(() => {
    if (selectSuperAdminSuccess) {
      FMDPStorage.set("selected-app", "SuperAdmin");
      FMDPStorage.set("selected-app-id", "superadmin");
      FMDPStorage.set("selected-client", "");
      navigate("/");
      resetSelectSuperAdmin();
    }
  }, [selectSuperAdminSuccess, selectSuperAdminError]);

  const initiateAppChange = (appId) => {
    if (appId !== selectedAppId) {
      setAppToBeSelected(appId);
      setLayoutSelectedAppId(appId);
    }
  };

  const getAppName = (app) => {
    let clientName = "";
    let names = app?.name?.split("-") || [];

    if (names.length > 0) {
      clientName = names[0];
    }
    if (app?.custom_name?.trim()) {
      return `${clientName} - ${app.custom_name}`;
    }
    return app.name;
  };

  const getHeaderNameAndLogo = () => {
    let { client, custom_name, name, image_url } = user?.client_apps?.filter(
      (i) => i?.id === FMDPStorage.get("selected-app-id")
    )[0];

    if (custom_name?.trim()) {
      return { name: custom_name, logo: image_url ? image_url : client?.image_url };
    } else {
      name = name.split("-").join(" ");
      return { name: name, logo: image_url ? image_url : client?.image_url };
    }
  };

  //set co_branding screen
  useEffect(() => {
    if (
      clientAppData?.user_sessions?.client_app?.client?.co_branding_screen === true ||
      clientAppData?.user_sessions?.client_app?.client?.co_branding_screen === false
    ) {
      dispatch(
        setFlashScreen(clientAppData?.user_sessions?.client_app?.client?.co_branding_screen)
      );
    }
  }, [dispatch, clientAppData]);

  const handleLanguageChange = (language) => {
    const updatedUser = { ...currentUserDetails, locale: language };
    FMDPStorage.set("current-user", updatedUser);

    updateUser({
      id: currentUserDetails?.id,
      email: currentUserDetails?.email,
      first_name: currentUserDetails?.first_name,
      last_name: currentUserDetails?.last_name,
      locale_id: language?.id,
    });
    window.location.reload();
  };

  // Change layout
  const handleLayoutChange = (layout) => {
    updateLayout({ id: currentUserDetails?.id, formData: { layout_id: layout.id } });
  };

  // Reload page after layout change
  useEffect(() => {
    if (isSuccessLayout) {
      window.location.reload();
    }
  }, [isSuccessLayout]);

  const goToDashboard = () => {
    FMDPStorage.set("app_features", null);
    FMDPStorage.set("selected-client", null);
    FMDPStorage.set("selected-app", null);
    FMDPStorage.set("selected-app-id", null);
    FMDPStorage.set("created-by-client-id", null);
    FMDPStorage.set("created-by-reseller", null);
    FMDPStorage.set("reseller", null);
    navigate("/app-selection");
  };

  const handleClickOutside = (event) => {
    if (dashboardRef.current && !dashboardRef.current.contains(event.target)) {
      // Clicked outside the config div, set showConfig to false
      setQuickDashBoard(false);
    }
  };

  useEffect(() => {
    // Add event listener on mount
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // Empty dependency array ensures the effect runs only on mount and unmount

  const handleGridSelection = (client) => {
    setGridSelectedApp(client);
    setShowModal(true);
  };

  useEffect(() => {
    if (appToBeSelected) {
      switchApplication();
    }
  }, [appToBeSelected]);

  useEffect(() => {
    if (selectClientAppSuccess) {
      setShowModal(false);
    }
  }, [selectClientAppSuccess]);

  useEffect(() => {
    if (showModal && !showConfirmationModal) {
      initiateAppChange(gridSelectedApp);
    }
  }, [showModal, showConfirmationModal]);

  const getLayoutClassName = (layout) => {
    if (isAlternativeUI) {
      if (layout === "Default") {
        return "";
      } else {
        return "active";
      }
    } else {
      if (layout === "Default") {
        return "active";
      } else {
        return "";
      }
    }
  };

  return (
    <header className="qi-header">
      <span className="branding">
        {getHeaderNameAndLogo()?.logo ? (
          <img alt={getHeaderNameAndLogo()?.name} src={getHeaderNameAndLogo()?.logo} />
        ) : (
          <img alt="" src={smallLogo} />
        )}
      </span>

      <h3 className="qi-header_title text-center">
        <strong className="qi-header_title_text" onClick={() => navigate("/")}>
          {getHeaderNameAndLogo().name}
        </strong>
      </h3>
      <div className="qi-header_nav">
        <div ref={dashboardRef} className="quick-dashboard">
          {(user?.client_apps?.length > 1 ||
            (user?.client_apps?.length === 1 && user?.superadmin)) && (
            <button onClick={() => setQuickDashBoard((prev) => !prev)}>
              <SvgIcon name={"grid"} />
            </button>
          )}
          {quickDashboard && (
            <div className="switch-apps">
              <Scrollbars autoHeight autoHeightMax="600px">
                <ul className="switch-apps_list">
                  <li className="switch-apps_list_item" onClick={goToDashboard}>
                    <SvgIcon name="back" wrapperClass="p-2" />
                    <strong>
                      {getLocalizedString("go_to_dashboard", "Clients and Applications")}
                    </strong>
                  </li>
                  {user.superadmin && (
                    <li
                      className={`switch-apps_list_item ${
                        selectedAppId === "superadmin" ? "active" : ""
                      }`}
                      onClick={() => {
                        handleGridSelection("superadmin");
                        setQuickDashBoard(false);
                      }}
                    >
                      <div className="application-icon-wrapper">
                        <SvgIcon name="crown" wrapperClass="crown-icon" svgClass="icon-crown" />
                      </div>
                      <span>{getLocalizedString("superadmin", "Superadmin")}</span>
                    </li>
                  )}
                  {user?.client_apps?.map((app, index) => (
                    <li
                      className={`switch-apps_list_item ${
                        selectedAppId === app?.id ? "active" : ""
                      }`}
                      key={index}
                      onClick={() => {
                        handleGridSelection(app.id);
                        setQuickDashBoard(false);
                        if (app?.client?.id === selectedClientId) {
                          setshowConfirmationModal(false);
                        } else {
                          setshowConfirmationModal(true);
                        }
                      }}
                    >
                      <div className="application-icon-wrapper">
                        {app.image_url ? (
                          <span className="application-icon_wrapper">
                            <img src={app?.image_url} className="application-icon" />
                          </span>
                        ) : app?.client?.image_url ? (
                          <span className="application-icon_wrapper">
                            <img src={app?.client?.image_url} className="application-icon" />
                          </span>
                        ) : (
                          <SvgIcon name="app-icon" wrapperClass="application-icon defult" />
                        )}
                      </div>
                      <span>{getAppName(app)}</span>
                    </li>
                  ))}
                </ul>
              </Scrollbars>
            </div>
          )}
        </div>
        <QIDropdown
          onHide={() => setDropDownSection("")}
          className="user-action-dropdown"
          toggleComponent={
            <div className="user-profile-name">
              <div className="user-profile-image-container">
                <img
                  className="user-profile-image"
                  alt="user-profile"
                  src={user?.thumbnail ? user?.thumbnail : userPlaceholder}
                />
              </div>
              <strong>
                {user.first_name || getLocalizedString("unknown", "Unknown")} {user.last_name || ""}
              </strong>
            </div>
          }
        >
          {dropDownSection === "" && (
            <ul className="user-dropdown">
              <li className="user-dropdown_user-info">
                <strong>{`${user.first_name} ${user.last_name}`}</strong>
                <p>{user.email || ""}</p>
              </li>

              {/* <li className="user-dropdown_link">
                <a
                  className="user-dropdown_link_item clickable"
                  onClick={() => setDropDownSection("application-switch")}
                >
                  <SvgIcon name="switch" />
                  <span>
                    {getLocalizedString("switch_applications", "Switch Applications")}
                  </span>
                </a>
              </li> */}

              <li className="user-dropdown_link">
                <a
                  className="user-dropdown_link_item clickable"
                  onClick={() => setDropDownSection("language-change")}
                >
                  <SvgIcon name="language" />
                  <span>{getLocalizedString("change_language", "Change Language")}</span>
                </a>
              </li>

              {/* <li className="user-dropdown_link">
                <a
                  className="user-dropdown_link_item clickable"
                  onClick={() => setDropDownSection("layout-change")}
                >
                  <SvgIcon name="layout" />
                  <span>{getLocalizedString("change_layout", "Change Layout")}</span>
                </a>
              </li> */}

              <li className="user-dropdown_link">
                <a
                  className="user-dropdown_link_item clickable"
                  onClick={() => setDropDownSection("layout-change")}
                >
                  <SvgIcon name="layout" />
                  <span>{getLocalizedString("change_layout", "Change Layout")}</span>
                </a>
              </li>

              <li className="user-dropdown_link">
                <a className="user-dropdown_link_item clickable" onClick={logOut}>
                  <SvgIcon name="log-out" />
                  <span>{getLocalizedString("sign_out", "Sign Out")}</span>
                </a>
              </li>
            </ul>
          )}
          {/*  {dropDownSection === "application-switch" && (
            <Scrollbars autoHeight autoHeightMax="600px">
              <ul>
                <li className="user-dropdown_link" onClick={() => setDropDownSection("")}>
                  <SvgIcon name="back" wrapperClass="p-2" />
                  <strong>
                    {getLocalizedString("switch_application", "Switch Application")}
                  </strong>
                </li>
                {user.superadmin && (
                  <li
                    className={`user-dropdown_link ${
                      selectedAppId === "superadmin" ? "active" : ""
                    }`}
                    onClick={() => initiateAppChange("superadmin")}
                  >
                    <div className="application-icon-wrapper">
                      <SvgIcon name="crown" wrapperClass="crown-icon" svgClass="icon-crown" />
                    </div>
                    <span>{getLocalizedString("superadmin", "Superadmin")}</span>
                  </li>
                )}
                {user?.client_apps?.map((app, index) => (
                  <li
                    className={`user-dropdown_link flex ${
                      selectedAppId === app.id ? "active" : ""
                    }`}
                    key={index}
                    onClick={() => initiateAppChange(app.id)}
                  >
                    <div className="application-icon-wrapper">
                      {app.image_url ? (
                        <span className="application-icon_wrapper">
                          <img src={app.image_url} className="application-icon" />
                        </span>
                      ) : (
                        <SvgIcon name="app-icon" wrapperClass="application-icon defult" />
                      )}
                    </div>
                    <span>{getAppName(app)}</span>
                  </li>
                ))}
              </ul>
            </Scrollbars>
          )} */}

          {dropDownSection === "layout-change" && (
            <Scrollbars autoHeight autoHeightMax="600px">
              <ul>
                <li className="user-dropdown_link" onClick={() => setDropDownSection("")}>
                  <SvgIcon name="back" wrapperClass="p-2" />
                  <strong>{getLocalizedString("change_language", "Change Layout")}</strong>
                </li>
                {layouts?.data?.map((layout) => (
                  <Scrollbars autoHeight autoHeightMax="600px">
                    <li
                      className={`user-dropdown_link ${getLayoutClassName(layout?.name)}`}
                      value={layout.id}
                      onClick={() => handleLayoutChange(layout)}
                    >
                      {layout.name}
                    </li>
                  </Scrollbars>
                ))}
              </ul>
            </Scrollbars>
          )}
          {dropDownSection === "language-change" && (
            <Scrollbars autoHeight autoHeightMax="600px">
              <ul>
                <li className="user-dropdown_link" onClick={() => setDropDownSection("")}>
                  <SvgIcon name="back" wrapperClass="p-2" />
                  <strong>{getLocalizedString("change_language", "Change Language")}</strong>
                </li>
                {languages?.data?.map((language) => (
                  <Scrollbars autoHeight autoHeightMax="600px">
                    <li
                      className={`user-dropdown_link ${
                        currentUserDetails?.locale?.id === language?.id ? "active" : ""
                      }`}
                      value={language.id}
                      onClick={() => handleLanguageChange(language)}
                    >
                      {language.name}
                    </li>
                  </Scrollbars>
                ))}
              </ul>
            </Scrollbars>
          )}
        </QIDropdown>
      </div>
      {showConfirmationModal && showModal && (
        <QIModal show={showModal} scrollable={true} className="confirm">
          <QIModalBody>
            <h2 className="page-title mb-4 text-center">
              {getLocalizedString("confirmation_alert", "Confirmation Alert") + "!"}
            </h2>
            <div className="confirmation-message">
              <span>
                <SvgIcon name="alert" className="confirmation-message_icon" />
              </span>
              <p className="text-center mb-4">
                {getLocalizedString(
                  "switch_application_message",
                  "You are about to switch application. Please save your existing work (if any) and confirm."
                )}
              </p>
              <p className="text-center">
                {getLocalizedString("are_you_sure", "Are you sure") + " ?"}
              </p>
              {(selectClientAppError || selectClientAppError) && (
                <p className="text-red-600">
                  {getLocalizedString(
                    "switch_application_wrong_message",
                    "Something went wrong, Unable to switch application"
                  )}
                </p>
              )}
            </div>
          </QIModalBody>
          <QIModalFooter>
            <QIButton
              onClick={() => {
                setShowModal(false);
              }}
              className="qi-btn secondary md"
              variant="secondary"
              size="sm"
            >
              {getLocalizedString("no", "No")}
            </QIButton>
            <QIButton
              onClick={() => {
                initiateAppChange(gridSelectedApp);
              }}
              size="sm"
              variant="danger"
              className="qi-btn primary md"
            >
              {getLocalizedString("yes", "Yes")}
            </QIButton>
          </QIModalFooter>
        </QIModal>
      )}
    </header>
  );
};
