import moment from "moment";
import { calculateDistance, getDuration, msToKmph } from "../utils";
import { getLocalizedString } from "../../../shared/translation";
import { capitalize } from "../utils";

export const header = [
  {
    label: getLocalizedString("start_time", "Start Time"),
    id: 1,
    nestedValue: true,
    getNestedValue: ({ start_time }) => {
      return <h1 title={start_time ? formatTime(start_time) : ""}>{formatTime(start_time)}</h1>;
    },
  },
  {
    label: getLocalizedString("duration", "Duration"),
    id: 2,
    nestedValue: true,
    getNestedValue: ({ duration }) => {
      return <h1>{getDuration(duration)}</h1>;
    },
  },
  {
    label: getLocalizedString("distance", "Distance"),
    id: 3,
    nestedValue: true,
    getNestedValue: ({ gps_distance }) => {
      const distance = calculateDistance(gps_distance);

      return (
        <h1>
          {distance} {!distance.includes("km") && !distance.includes("m") && "km"}
        </h1>
      );
    },
  },
  {
    label: getLocalizedString("avg_speed", "Avg Speed"),
    id: 4,
    nestedValue: true,
    getNestedValue: ({ avg_speed }) => {
      const speed = msToKmph(avg_speed);
      return <h1>{speed ? Number(speed).toFixed(2) + " km/h" : "NA"}</h1>;
    },
  },
  {
    label: getLocalizedString("max_speed", "Max Speed"),
    id: 5,
    nestedValue: true,
    getNestedValue: ({ max_speed }) => {
      const speed = msToKmph(max_speed);
      return <h1>{speed ? Number(speed).toFixed(2) + " km/h" : "NA"}</h1>;
    },
  },
  {
    label: getLocalizedString("status", "Status"),
    id: 5,
    nestedValue: true,
    getNestedValue: ({ status }) => {
      status = status?.replace("_", " ");
      return <h1 title={capitalize(status)}>{capitalize(status)}</h1>;
    },
  },
];

function formatTime(time) {
  return moment(time).format("DD/MM/YY - HH:mm A");
}
