import React, { useEffect, useRef, useState } from "react";
import { useOutsideAlerter } from "../../hooks";
import sprite from "../../assets/icons.svg";
import { SvgIcon } from "../../containers/Shared/SvgIcon";

export const QIInput = ({
  label,
  labelHints = "",
  value = "",
  onChange,
  onChangeEdit,
  children,
  className = "",
  readOnly,
  error,
  warningMessage = "",
  handleWarningMessage = () => {},
  icon = {},
  textarea = false,
  disabled = false,
  type = "text",
  edit = false,
  customLabelClass = "",
  customWrapperClass = "",
  focus = false,
  autoComplete,
  ...props
}) => {
  const { className: iconClass, src } = icon;
  const [showPassword, setShowPassword] = useState(false);
  const [disableEdit, setDisableEdit] = useState(false);
  const [passwordEdit, setPasswordEdit] = useState("");

  const [isFocus, setIsFocus] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (focus) {
      setIsFocus(true);
      inputRef.current.focus();
    }
  }, [focus]);

  useEffect(() => {
    if (disabled) {
      setDisableEdit(true);
    }
  }, []);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setShowPassword(false));

  return (
    <div
      className={`qi-input qi-form-element ${error && !disabled ? "error" : ""} ${
        edit ? `${disableEdit && "disabled"}` : `${disabled && "disabled"} `
      }  ${className || ""}`}
      onClick={() => inputRef.current.focus()}
      ref={wrapperRef}
    >
      {label && (
        <label className={`qi-input_label ${customLabelClass}`} data-testid="input-label">
          {label}
          {labelHints && <span className="qi-input_label_hints">{labelHints}</span>}
        </label>
      )}
      <div className={`qi-input_wrapper ${customWrapperClass}`}>
        <div className="qi-input_item">
          {textarea ? (
            <textarea
              value={value}
              className={`qi-input_text w-full ${readOnly && "qi-input_text_readonly"} `}
              onChange={onChange}
              readOnly={readOnly}
              ref={inputRef}
              disabled={disableEdit}
              autoFocus={isFocus}
              {...props}
            />
          ) : edit && !disableEdit ? (
            <input
              value={passwordEdit}
              onChange={(e) => setPasswordEdit(e.target.value)}
              className={`qi-input_text w-full ${readOnly && "qi-input_text_readonly"} temp`}
              autoFocus={isFocus}
              ref={inputRef}
              autoComplete={autoComplete}
            />
          ) : (
            <input
              value={value}
              className={`qi-input_text w-full ${readOnly && "qi-input_text_readonly"} `}
              autoComplete={autoComplete}
              onChange={onChange}
              readOnly={readOnly}
              ref={inputRef}
              disabled={disabled}
              type={type === "password" ? `${showPassword ? "text" : "password"}` : type}
              autoFocus={isFocus}
              {...props}
            />
          )}
          {src && typeof src === "string" ? (
            <span>
              <svg className={`icon ${iconClass || ""}`}>
                <use href={src}></use>
              </svg>
            </span>
          ) : (
            src
          )}
          {type == "password" && value && (
            <div
              className="input-group-prepend password-filed"
              onClick={() => setShowPassword((prev) => !prev)}
            >
              {!edit && <span className={`password-show-hide ${showPassword && "show"}`} />}
            </div>
          )}
          {edit &&
            (disableEdit ? (
              <SvgIcon
                name="edit"
                title="Edit"
                wrapperClass="action-btn"
                svgClass="icon-edit"
                onClick={() => {
                  setDisableEdit(false);
                  handleWarningMessage(true);
                }}
              />
            ) : (
              <SvgIcon
                name="tick"
                title="Tick"
                wrapperClass="icon-wrapper tick-icon action-btn"
                svgClass="icon icon-tick"
                onClick={() => {
                  onChangeEdit(passwordEdit);
                  setDisableEdit(true);
                  handleWarningMessage(false);
                }}
              />
            ))}

          {children}
        </div>

        {!readOnly && error && !disabled && (
          <div className="qi-input_error">
            <span className="icon-wrapper">
              <svg className="icon">
                <use href={`${sprite}#info`}></use>
              </svg>
            </span>
            <p
              className="qi-input_error_message "
              dangerouslySetInnerHTML={{ __html: `${error}` }}
            ></p>
          </div>
        )}
        {warningMessage.length > 0 && (
          <div className="qi-input_warning">
            <p className="qi-input_warning_message ">{warningMessage}</p>
          </div>
        )}
      </div>
    </div>
  );
};
