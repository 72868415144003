import React, { useState } from "react";
import { useSearchQuery, usePagination } from "../../../hooks";
import { AdminSharedHeader, AdminSharedTable, AdminSharedDeleteModal } from "../Shared";
import { useQuery, useDelete } from "../../../hooks";
import { useGetDisplayConfigsQuery, useDeleteDisplayConfigMutation } from "../services";
import { DisplayConfigurationsForm } from "./Form";
import { getLocalizedString } from "../../../shared/translation";
import { DetailsDropDownView, TableDropDownView } from "../../ManagementPortal/Shared";
import { TickOrCross } from "../../Shared";
import { Link } from "react-router-dom";
import { useGetVariablesQuery } from "../services";
import { QIModal, QIModalBody, QIModalHeader } from "../../../components";

export const DisplayConfigurationsContainer = () => {
  const [idToDelete, setIdToDelete] = useState(null);
  const [idToEdit, setIdToEdit] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [showVariableModal, setVariableModal] = useState(false);
  const [variableModalData, setVariableModalData] = useState([]);
  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, setAdvanceSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_cont",
  });

  const queryParams = {
    page: page,
    per_page: perPage,
    order_by: "display_configurations.created_at",
    order_dir: "desc",
  };

  const headers = [
    { label: "Display Name", key: "name", className: "display_name" },
    { label: "Map", key: "map", className: "map" },
    {
      label: getLocalizedString("map_key", "API Key"),
      type: "component",
      className: "api-key more",
      component: ({ data }) => {
        return <DetailsDropDownView data={data?.map_key} />;
      },
    },
    {
      label: "Dark Mode",
      nestedValue: true,
      getNestedValue: ({ dark_mode }) => {
        return <TickOrCross flag={dark_mode} />;
      },
      className: "enabled",
    },
    {
      label: "Variables",
      type: "component",
      className: "variables",
      component: ({ data }) => {
        let variable_ids = Array.isArray(data?.variable_ids)
          ? data.variable_ids.map((value) => {
              return value?.id;
            })
          : [];

        const getVariables = (index) => {
          return data?.variable_ids?.find((value) => value?.id === index);
        };

        let selectedVariables = variables?.data
          ?.map((variable) => {
            if (variable_ids?.includes(variable?.id)) {
              return {
                name: variable?.display_name,
                precision: getVariables(variable?.id)?.precision,
              };
            }
          })
          .filter(Boolean);
        return (
          <>
            <Link
              onClick={() => {
                setVariableModal(true);
                setVariableModalData(selectedVariables);
              }}
              style={{ textDecoration: "none" }}
            >
              {variable_ids?.length}
            </Link>
          </>
        );
      },
    },
  ];

  const variableHeaders = [
    { label: "Display Name", key: "name", className: "display_name" },
    { label: "Precision", key: "precision", className: "precision" },
  ];

  /* API Calls */

  // Get data for target variables list
  const { data: variables, isLoading } = useGetVariablesQuery({
    per_page: 2000,
    order_by: "variables.display_name",
    classification_type: ["tracking_data_device", "tracking_data_custom"],
  });

  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetDisplayConfigsQuery({
    ...queryParams,
    q,
  });

  const {
    deleteItem: deleteDisplayConfiguration,
    deleteErrorMsg,
    setDeleteErrorMsg,
  } = useDelete({
    deleteMutation: useDeleteDisplayConfigMutation,
    closeModal: () => setShowDeleteWarning(false),
  });

  /* Api Calls End */

  /* Actions */
  const createNewHandler = () => {
    setShowForm(true);
    setIdToEdit(null);
  };

  const deleteClickHandler = (datum) => {
    setShowDeleteWarning(true);
    setIdToDelete(datum.id);
  };

  const editClickHandler = (datum) => {
    setShowForm(true);
    setIdToEdit(datum.id);
  };

  return (
    <>
      <>
        <article className="main-container">
          <AdminSharedHeader
            createNewHandler={createNewHandler}
            groupName="other"
            heading="Configurations"
            handleSearchKey={setSimpleSearch}
            simpleSearchPlaceHolder="Search by Name"
          />
          <AdminSharedTable
            isLoading={isFetching}
            error={error}
            isSuccess={isSuccess}
            headers={headers}
            data={data?.data}
            pagination={{ ...pagination, count: data.total_count }}
            onDelete={deleteClickHandler}
            onEdit={editClickHandler}
            className="display"
            auditResource="Display"
            auditKey="name"
          />
        </article>

        {showForm && (
          <DisplayConfigurationsForm idToEdit={idToEdit} closeForm={() => setShowForm(false)} />
        )}

        <AdminSharedDeleteModal
          show={showDeleteWarning}
          resourceName="Display"
          error={deleteErrorMsg}
          getDeleteItemName={() => {}}
          onHide={() => {
            setDeleteErrorMsg();
            setShowDeleteWarning(false);
          }}
          onDelete={() => deleteDisplayConfiguration({ id: idToDelete })}
        />
        <QIModal show={showVariableModal} size={"large"}>
          <QIModalHeader onHide={() => setVariableModal(false)}>
            <h5>Variables</h5>
          </QIModalHeader>
          <QIModalBody>
            <AdminSharedTable
              isLoading={isLoading}
              pagination={{ ...pagination, count: variableModalData?.length }}
              isSuccess={true}
              data={variableModalData || []}
              headers={variableHeaders}
              className="display_config"
            />
          </QIModalBody>
        </QIModal>
      </>
    </>
  );
};
