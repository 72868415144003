import React, { useState, useEffect } from "react";
import { Icon, Style, Stroke } from "ol/style.js";
import { QIMapLoader } from "../../../components";
import { useGetVehicleRoutingQuery } from "../services";
import { locationImg, startPointMarker, endPointMarker } from "../assets";
import moment from "moment";
import { removeDuplicates } from "../utils";
import { toast } from "react-toastify";
import mapboxgl from "mapbox-gl";

/**
 * Component for displaying and tracking vehicle routes on a map.
 * @param {Object} props - Component props.
 * @param {string} props.dockAlign - Alignment of the map container.
 * @param {string} props.deviceId - ID of the device/vehicle.
 * @param {Object} props.realTimeData - Real-time data of the vehicle.
 * @param {function} props.setTrackMapRef - Function to set the map reference.
 * @param {Object} props.trackMapRef - Reference to the map.
 * @param {boolean} props.mapExpand - Whether the map is expanded.
 * @param {function} props.setResetCount - Function to reset a count.
 * @param {Object} props.selectedRange - Selected time range for data retrieval.
 * @param {function} props.maximizeMinimize - Function to maximize or minimize the map.
 */
export const VehicleTrack = ({
  dockAlign,
  deviceId,
  realTimeData,
  setTrackMapRef,
  trackMapRef,
  mapExpand,
  setResetCount,
  selectedRange,
  maximizeMinimize,
  mapProvider,
  mapboxAccessToken,
  googleMapsApiKey,
}) => {
  const [trackData, setTrackData] = useState([]); // State to hold tracked vehicle data
  const [page, setPage] = useState(0); // Pagination page for fetched data
  const [totalCount] = useState(200); // Total count of data to fetch
  const [count, setCount] = useState(0); // Count for layer management
  const [initialLoad, setInitialLoad] = useState(true); // Flag to check if initial load

  // Reset state and map layers when selected time range or device ID changes
  useEffect(() => {
    setPage(0);
    setTrackData([]);
    setInitialLoad(true); // Set initial load to true when deviceId or range changes
  }, [selectedRange, deviceId]);

  // Fetch vehicle routing data using a custom hook
  const { data: resData, error } = useGetVehicleRoutingQuery(
    {
      deviceId,
      per_page: totalCount,
      page,
      start_time: moment(selectedRange.startDate).valueOf(),
      end_time: moment(selectedRange.endDate).valueOf(),
      isTime: true,
    },
    { refetchOnMountOrArgChange: true }
  );

  // Error message display
  useEffect(() => {
    if (error?.data?.message) {
      toast.error(error?.data?.message);
    }
  }, [error]);

  // Process the fetched and real-time data to update the tracked vehicle data
  useEffect(() => {
    try {
      if (deviceId) {
        if (resData?.total_count && page <= Math.round(resData?.total_count / totalCount)) {
          resData?.data.forEach((device_data) => {
            if (
              device_data?.gps &&
              device_data?.gps?.position?.lat &&
              device_data?.gps?.position?.lng
            ) {
              let h = {
                source_id: device_data?.source_id,
                lat: device_data?.gps?.position?.lat,
                lng: device_data?.gps?.position?.lng,
                direction: device_data?.velocity?.direction,
                speed: device_data?.velocity?.speed,
                "010d_vehicle_speed": device_data?.obd?.["010d_vehicle_speed"],
              };
              setTrackData((prev) => [...prev, h]);
            }
          });
          setPage((c) => c + 1);
        }
      }

      // Update tracked data with real-time data if within the last 25 hours
      const currentTime = moment().format("YYYY-MM-DD HH:mm:ss");
      const selectedTime = moment(selectedRange.startDate).format("YYYY-MM-DD HH:mm:ss");
      const duration = moment.duration(moment(currentTime).diff(moment(selectedTime)));
      const hours = duration.asHours();

      if (hours <= 25 && realTimeData?.gps && realTimeData?.gps?.position?.is_valid) {
        let h = {
          source_id: realTimeData?.source_id,
          lat: realTimeData?.gps?.position?.lat,
          lng: realTimeData?.gps?.position?.lng,
          direction: realTimeData?.velocity?.direction,
          speed: realTimeData?.velocity?.speed,
          "010d_vehicle_speed": realTimeData?.obd?.["010d_vehicle_speed"],
        };
        setTrackData((prev) => [h, ...prev]);
      }
    } catch (e) {
      console.log(e);
    }
  }, [resData, realTimeData, selectedRange]);

  // Update the map with the tracked vehicle data
  useEffect(() => {
    if (mapProvider === "qiMap") {
      try {
        if (trackMapRef && trackData.length && deviceId) {
          let layerName = `track_${count}`;

          // Remove previous layer if it exists (by name) and add a new one
          trackMapRef.removeLayer(`track_${count - 1}`);
          trackMapRef.addLayer({ name: layerName });

          // Draw the first and last points of the track with specific styles
          trackMapRef.drawTrack({
            layerName: layerName,
            fitWithinView: false, // Disable auto-fit for updates
            trackType: "point",
            data: [{ coordinates: [trackData[0]] }],
            style: {
              point: {
                style: (feature) => {
                  return new Style({
                    image: new Icon({
                      color: "#eaff00",
                      crossOrigin: "anonymous",
                      src: locationImg,
                      imgSize: [38, 38],
                      rotation: feature.direction,
                    }),
                  });
                },
              },
            },
          });

          trackMapRef.drawTrack({
            layerName: layerName,
            fitWithinView: false, // Disable auto-fit for updates
            trackType: "point",
            data: [{ coordinates: [trackData[trackData.length - 1]] }],
            style: {
              point: {
                style: (feature) => {
                  return new Style({
                    image: new Icon({
                      color: "#0000ff",
                      crossOrigin: "anonymous",
                      src: locationImg,
                      imgSize: [16, 16],
                      rotation: feature.direction,
                    }),
                  });
                },
              },
            },
          });

          // Draw the track line connecting all points
          trackMapRef.drawTrack({
            layerName: layerName,
            fitWithinView: false, // Disable auto-fit for updates
            trackType: "line",
            data: [{ coordinates: removeDuplicates(trackData) }],
            style: {
              line: {
                style: (feature) => {
                  return new Style({
                    stroke: new Stroke({
                      color: "#000",
                      width: 3,
                    }),
                  });
                },
              },
            },
          });

          trackMapRef.performFit(layerName); // Fit to the layer
          // Only fit the map on initial load
          /* if (initialLoad) {
            setInitialLoad(false); // Disable initial load after first fit
          } */

          setCount((c) => c + 1);
        } else {
          trackMapRef?.removeLayer(`track_${count - 1}`);
        }
      } catch (e) {
        console.log(e);
      }
    }
    if (mapProvider === "mapbox") {
      try {
        // Maintain references to the layers and markers
        let currentLayerIds = [];

        // Helper function to remove a layer and its source
        const removeLayerAndSource = (id) => {
          if (trackMapRef.getLayer(id)) {
            trackMapRef.removeLayer(id);
          }
          if (trackMapRef.getSource(id)) {
            trackMapRef.removeSource(id);
          }
        };

        // Function to clear all previous layers and sources
        const clearPreviousLayers = () => {
          currentLayerIds.forEach((layerId) => {
            removeLayerAndSource(layerId);
          });
          currentLayerIds = [];
        };

        // Function to clear previous markers
        const clearPreviousMarkers = () => {
          if (window.startMarker) {
            window.startMarker.remove();
            window.startMarker = null;
          }
          if (window.endMarker) {
            window.endMarker.remove();
            window.endMarker = null;
          }
        };

        if (trackMapRef && trackData.length && deviceId) {
          const layerName = `track_${count}`;

          // Clear previous layers and markers
          clearPreviousLayers();
          clearPreviousMarkers();

          // Add track line as a source
          trackMapRef.addSource(`${layerName}_line`, {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: trackData.map((point) => [point.lng, point.lat]),
              },
            },
          });

          trackMapRef.addLayer({
            id: `${layerName}_line`,
            type: "line",
            source: `${layerName}_line`,
            paint: {
              "line-color": "#000", // Black line
              "line-width": 3,
            },
          });

          // Track current layer ID for future cleanup
          currentLayerIds.push(`${layerName}_line`);

          // Fit the map to the track bounds
          const bounds = trackData.reduce(
            (bounds, coord) => bounds.extend([coord.lng, coord.lat]),
            new mapboxgl.LngLatBounds(
              [trackData[0].lng, trackData[0].lat],
              [trackData[0].lng, trackData[0].lat]
            )
          );

          trackMapRef.fitBounds(bounds, { padding: 20 });

          // Add start and end markers
          window.startMarker = new mapboxgl.Marker()
            .setLngLat([trackData[0].lng, trackData[0].lat])
            .addTo(trackMapRef);

          window.endMarker = new mapboxgl.Marker()
            .setLngLat([trackData[trackData.length - 1].lng, trackData[trackData.length - 1].lat])
            .addTo(trackMapRef);

          // Increment the count for the next layer
          setCount((c) => c + 1);
        } else {
          // Clear all layers and markers if no valid data
          clearPreviousLayers();
          clearPreviousMarkers();
        }
      } catch (e) {
        console.error("Error:", e);
      }
    }
    if (mapProvider === "google") {
      try {
        if (trackMapRef && trackData.length && deviceId) {
          let layerName = `track_${count}`;

          // Remove previous tracks (if they exist)
          for (let i = 0; i < count; i++) {
            const previousTrack = window[`track_${i}_google`];
            if (previousTrack) {
              previousTrack.forEach((overlay) => overlay.setMap(null));
            }
          }

          // Create start and end markers using the default Google Maps marker
          const startMarker = new google.maps.Marker({
            position: { lat: trackData[0].lat, lng: trackData[0].lng },
            map: trackMapRef,
            // No custom icon, defaults to Google Maps marker
          });

          const endMarker = new google.maps.Marker({
            position: {
              lat: trackData[trackData.length - 1].lat,
              lng: trackData[trackData.length - 1].lng,
            },
            map: trackMapRef,
            // No custom icon, defaults to Google Maps marker
          });

          // Create a polyline using the default Google Maps polyline
          const trackLine = new google.maps.Polyline({
            path: trackData.map((point) => ({
              lat: point.lat,
              lng: point.lng,
            })),
            geodesic: true,
            // Default polyline styling will be used
          });

          trackLine.setMap(trackMapRef);

          // Store the current track
          window[`track_${count}_google`] = [startMarker, endMarker, trackLine];

          // Adjust the map bounds to fit the track
          const bounds = new google.maps.LatLngBounds();
          trackData.forEach((point) => bounds.extend(new google.maps.LatLng(point.lat, point.lng)));
          trackMapRef.fitBounds(bounds);

          // Increment the count for the next layer
          setCount((c) => c + 1);
        } else {
          // Clear all previous Google Maps markers and lines
          for (let i = 0; i < count; i++) {
            const previousTrack = window[`track_${i}_google`];
            if (previousTrack) {
              previousTrack.forEach((overlay) => overlay.setMap(null));
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [trackMapRef, trackData, deviceId, initialLoad, mapProvider]);

  return (
    <div className={`map-container ${dockAlign && "qiMap-split"}`}>
      <QIMapLoader
        containerId="device-map-container"
        mapExpand={mapExpand}
        setMapRef={setTrackMapRef}
        dockAlign={dockAlign}
        maximizeMinimize={maximizeMinimize}
        mapProvider={mapProvider}
        mapboxAccessToken={mapboxAccessToken}
        googleMapsApiKey={googleMapsApiKey}
      />
    </div>
  );
};
