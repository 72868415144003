import React, { useEffect, useState, useRef } from "react";
import { useSearchParams, NavLink } from "react-router-dom";
import { QIButton } from "../../../components";
import { CurrentUser } from "../../../shared/currentUser";
import sprite from "../../../assets/icons.svg";
import { SvgIcon } from "../../Shared";
import { QISimpleSearch } from "../../../components";
import { getLocalizedString } from "../../../shared/translation";
import { useGetContainersQuery, useGetPluginsQuery } from "../services";
import { usePagination, useSearchQuery } from "../../../hooks";

let timer;
export const MPSharedHeader = ({
  createNewHandler,
  groupName,
  heading,
  resourceName,
  handleSearchKey,
  filterText = "",
  handleAdvanceSearchKeys,
  simpleSearchPlaceholder = "",
  defaultSearchType = "simple",
  beforeSearchDom = null,
  className = "",
  children,
  skipCanCreate,
  uploadCsvHandler,
  addNewLabel = "",
  onEdit,
  selectedContainer,
}) => {
  const currentUser = new CurrentUser(resourceName);
  const [searchParams] = useSearchParams();
  const paramsSearch = searchParams.get("q");
  const [searchKey, setSearchKey] = useState(paramsSearch || "");
  const [searchType, setSearchType] = useState(defaultSearchType);

  const groupedRoutes = {
    device: [
      {
        label: getLocalizedString("devices", "Devices"),
        path: "devices/list",
      },
      {
        label: getLocalizedString("models", "Models"),
        path: "devices/models",
      },
    ],
    vehicle: [
      {
        label: getLocalizedString("vehicles", "Vehicles"),
        path: "vehicles/list",
      },
      {
        label: getLocalizedString("specifications", "Specifications"),
        path: "vehicles/specifications",
      },
      {
        label: getLocalizedString("variants", "Variants"),
        path: "vehicles/variants",
      },

      {
        label: getLocalizedString("models", "Models"),
        path: "vehicles/manufacturers_models",
      },
      {
        label: getLocalizedString("manufacturers", "Manufacturers"),
        path: "vehicles/manufacturers",
      },
      {
        label: getLocalizedString("classes", "Classes"),
        path: "vehicles/classes",
      },
      {
        label: getLocalizedString("types", "Types"),
        path: "vehicles/types",
      },
    ],
    group: [
      {
        label: getLocalizedString("vehicles", "Vehicles"),
        path: "groups/vehicle",
      },
      {
        label: getLocalizedString("devices", "Devices"),
        path: "groups/device",
      },
      {
        label: getLocalizedString("users", "Users"),
        path: "groups/user",
      },
    ],
    configurations: [
      {
        label: getLocalizedString("backends", "Backends"),
        path: "configurations/backends",
      },
      {
        label: getLocalizedString("geofences", "Geofences"),
        path: "configurations/geofence",
      },
      {
        label: getLocalizedString("events", "Events"),
        path: "configurations/events",
      },
      {
        label: getLocalizedString("event_plans", "Event Plans"),
        path: "configurations/event_plans",
      },
      {
        label: getLocalizedString("trips", "Trips"),
        path: "configurations/trips",
      },
      {
        label: getLocalizedString("reports", "Reports"),
        path: "configurations/report",
      },
    ],
    users_role: [
      {
        label: getLocalizedString("users", "Users"),
        path: "users_roles/users",
      },
      {
        label: getLocalizedString("roles", "Roles"),
        path: "users_roles/roles",
      },
    ],
    client_config: [
      {
        label: getLocalizedString("portal_customization", "Portal Customization"),
        path: "client_config/portal_customization",
      },
      {
        label: getLocalizedString("api_keys", "API Keys"),
        path: "client_config/api_keys",
      },
      {
        label: getLocalizedString("plugins", "Plugins"),
        path: "client_config/plugins_manager",
      },
      {
        label: getLocalizedString("containers", "Containers"),
        path: "client_config/containers",
      },
    ],
    simacrds: [
      {
        label: getLocalizedString("sim_cards", "SIM Cards"),
        path: "sim_cards",
      },
    ],
  };

  const handleSearchChange = (value) => {
    setSearchKey(value);

    clearTimeout(timer);
    // Debouncing for 500 seconds
    timer = setTimeout(() => {
      handleSearchKey(value);
    }, 500);
  };

  const handleSearchSwitch = () => {
    if (searchType === "simple") {
      handleSearchKey("");
      setSearchType("advance");
    } else {
      handleAdvanceSearchKeys(null);
      setSearchType("simple");
    }
  };

  const { page, setPage, perPage } = usePagination();

  const { q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_or_path_cont",
  });

  const { data = { data: [], total_count: 0 } } = useGetPluginsQuery({
    page,
    per_page: perPage,
    order_by: "plugins.created_at",
    order_dir: "desc",
    q,
  });

  const enabledPlugins = data?.data.filter((plugin) => plugin.is_enabled);

  const routes = ["devices", "vehicles", "groups", "configurations", "users_roles"];

  for (let i = 1; i <= routes.length; i++) {
    let addPlugins = [];
    addPlugins = enabledPlugins?.filter((plugin) =>
      plugin.mp_containers.map((s) => parseInt(s, 10)).includes(i)
    );
    if (addPlugins?.length > 0) {
      for (let j = 0; j < addPlugins.length; j++) {
        let groupName = Object.keys(groupedRoutes)[i - 1];
        if (groupedRoutes[groupName]) {
          groupedRoutes[groupName] = [
            ...groupedRoutes[groupName],
            {
              label: getLocalizedString(addPlugins[j].name, addPlugins[j].name),
              path: `${routes[i - 1]}/${groupName}/plugin/${addPlugins[j].path}`,
            },
          ];
        }
      }
    }
  }

  const { data: newContainers } = useGetContainersQuery({ page: 0, per_page: 500 });

  const mpcontainers =
    newContainers?.data &&
    newContainers?.data?.filter((container) =>
      container?.client_app_names.includes("Management Portal")
    );

  mpcontainers?.forEach((container) => {
    const groupName = container.name.replace(/\s+/g, "");
    groupedRoutes[groupName] = [];

    const filteredPlugins = enabledPlugins?.filter((plugin) =>
      container.plugin_ids.includes(plugin.id)
    );

    const plugins = filteredPlugins?.sort((a, b) => {
      const indexA = container.plugin_ids.indexOf(a.id);
      const indexB = container.plugin_ids.indexOf(b.id);
      return indexA - indexB;
    });

    plugins?.forEach((plugin, index) => {
      if (index === 0) {
        groupedRoutes[groupName].push({
          label: getLocalizedString(plugin.name, plugin.name),
          path: `container/${container.path}/primaryPlugin`,
        });
      } else {
        groupedRoutes[groupName].push({
          label: getLocalizedString(plugin.name, plugin.name),
          path: `container/${container.path}/header/plugin/${plugin.path}`,
        });
      }
    });
  });
  /* Tracking */
  const tpContainers =
    newContainers?.data &&
    newContainers?.data?.filter((container) => container?.client_app_names.includes("Tracking"));

  tpContainers?.forEach((container) => {
    const groupName = container.name.replace(/\s+/g, "");
    groupedRoutes[groupName] = [];

    const filteredPlugins = enabledPlugins?.filter((plugin) =>
      container.plugin_ids.includes(plugin.id)
    );

    const plugins = filteredPlugins?.sort((a, b) => {
      const indexA = container.plugin_ids.indexOf(a.id);
      const indexB = container.plugin_ids.indexOf(b.id);
      return indexA - indexB;
    });

    plugins?.forEach((plugin, index) => {
      if (index === 0) {
        groupedRoutes[groupName].push({
          label: getLocalizedString(plugin.name, plugin.name),
          path: `container/${container.path}/primaryPlugin`,
          app: "Tracking",
        });
      } else {
        groupedRoutes[groupName].push({
          label: getLocalizedString(plugin.name, plugin.name),
          path: `container/${container.path}/header/plugin/${plugin.path}`,
          app: "Tracking",
        });
      }
    });
  });

  /* Tracking End */

  useEffect(() => {
    let query = searchParams.get("q");

    if (query != searchKey) {
      setSearchKey(query ? query : "");
    }
  }, [searchParams]);

  useEffect(() => {
    return () => clearTimeout(timer);
  }, []);

  // Horizontal scroll for responsive UI
  const elementRef = useRef(null);

  // Visibility for left arrow
  const [arrowVisibleLeft, setArrowVisibleLeft] = useState(true);

  // Visibility for right arrow
  const [arrowVisibleRight, setArrowVisibleRight] = useState(false);

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;

    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);

      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowVisibleLeft(true);
      } else {
        setArrowVisibleLeft(false);
      }
      if (
        Math.trunc(element.scrollWidth - element.clientWidth) === Math.ceil(element.scrollLeft) ||
        Math.trunc(element.scrollWidth - element.clientWidth) === Math.trunc(element.scrollLeft)
      ) {
        setArrowVisibleRight(true);
      } else {
        setArrowVisibleRight(false);
      }
    }, speed);
  };

  return (
    <>
      <header className={`main-container_header ${className}`}>
        <div className="main-container_header_title-and-search-group">
          <h3 className="page-title xl">{heading}</h3>
          {beforeSearchDom}
          {searchType === "simple" && handleSearchKey && (
            <QISimpleSearch
              searchKey={searchKey}
              handleSearchChange={handleSearchChange}
              handleAdvanceSearchKeys={handleAdvanceSearchKeys}
              handleSearchSwitch={handleSearchSwitch}
              simpleSearchPlaceholder={simpleSearchPlaceholder}
            />
          )}

          {searchType === "advance" && (
            <div className="search-form-container">
              {defaultSearchType == "simple" && (
                <SvgIcon
                  wrapperClass="back-icon clickable"
                  svgClass="icon-back"
                  name="back"
                  onClick={handleSearchSwitch}
                />
              )}
              {children}
            </div>
          )}
        </div>

        <div className="header-buttons-wrapper">
          {uploadCsvHandler && (
            <QIButton
              className="qi-btn primary md"
              icon={{ src: `${sprite}#upload`, className: "upload-icon" }}
              onClick={uploadCsvHandler}
            >
              {getLocalizedString("import", "Import")}
            </QIButton>
          )}

          {createNewHandler && (currentUser.canCreate || skipCanCreate) && (
            <QIButton
              className="qi-btn primary md"
              icon={{ src: `${sprite}#add`, className: "add-icon" }}
              onClick={createNewHandler}
            >
              {addNewLabel ? addNewLabel : getLocalizedString("create_new", "Add New")}
            </QIButton>
          )}
          {/*  {onEdit && selectedContainer && (
            <QIButton
              className="qi-btn primary md"
              icon={{ src: `${sprite}#edit`, className: "edit-icon" }}
              onClick={() => onEdit(selectedContainer)}
            >
              {getLocalizedString("edit_container", "Edit Container")}
            </QIButton>
          )} */}
        </div>
      </header>

      {(filterText || groupedRoutes[groupName]) && (
        <div
          className={`secondary-header-wrapper ${
            groupedRoutes[groupName] ? "" : "showing-only-shortlisted"
          }`}
        >
          {groupedRoutes[groupName] && (
            <aside className="main-container_secondary-header">
              {groupedRoutes[groupName] && (
                <nav className="main-container_header_link">
                  <span className="tab-slider">
                    {!arrowVisibleLeft && (
                      <div className="slide">
                        <SvgIcon
                          name="up-arrow"
                          wrapperClass="previous"
                          onClick={() => {
                            handleHorizantalScroll(elementRef.current, 25, 100, -10);
                          }}
                        />
                      </div>
                    )}
                  </span>

                  <div class="scroll-wrapper" ref={elementRef}>
                    {groupedRoutes[groupName].map((link, index) => {
                      return (
                        <NavLink
                          to={
                            `/${link.app === "Tracking" ? "tracking" : "management_portal"}/${
                              link.path
                            }` || ""
                          }
                          className="main-container_header_link_item"
                          key={index}
                        >
                          <strong title={link.label} className="mx-1">
                            {link.label}
                          </strong>
                        </NavLink>
                      );
                    })}
                  </div>

                  <span className="tab-slider">
                    {!arrowVisibleRight && (
                      <div className="slide">
                        <SvgIcon
                          name="up-arrow"
                          wrapperClass="previous"
                          svgClass="rotate-180"
                          onClick={() => {
                            handleHorizantalScroll(elementRef.current, 25, 100, 10);
                          }}
                        />
                      </div>
                    )}
                  </span>
                </nav>
              )}
            </aside>
          )}

          {filterText && <p className="showing-shortlisted">{filterText}</p>}
        </div>
      )}
    </>
  );
};
