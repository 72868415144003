import { useState, useEffect, useCallback } from "react";
import { VehicleMap } from "../Vehicles/VehicleMap";
import { FMDPStorage } from "../../../shared/helper";
import { useGetDevicesQuery, useGetVehiclesQuery } from "../../ManagementPortal/services";
import { useGetDevicesTrackingQuery } from "../services";
import { useWebSocket } from "../../../hooks";
import { Icon, Style, Fill, Stroke, Text } from "ol/style.js";
import { locationImg } from "../assets/mapIcons";
import mapboxgl from "mapbox-gl";
import { debounce } from "../utils";

// Custom hook to get client configuration
const useClientConfig = () => {
  const [state, setState] = useState({
    clientId: null,
    clientConfig: {},
    mapProvider: "qiMap",
    mapboxAccessToken: null,
    googleMapsApiKey: null,
  });

  useEffect(() => {
    try {
      const selectedClientId = JSON.parse(localStorage.getItem("selected-app-id"));
      const currentUser = JSON.parse(localStorage.getItem("current-user"))?.client_apps?.find(
        (item) => item.id === selectedClientId
      );

      if (currentUser?.client?.config_json) {
        const config = JSON.parse(currentUser?.client?.config_json);
        const mapConfig = config?.tracking?.web?.map;

        setState({
          clientId: currentUser?.client?.id,
          clientConfig: config,
          mapProvider: mapConfig?.provider || "qiMap",
          mapboxAccessToken: mapConfig?.provider === "mapbox" ? mapConfig.mapboxAccessToken : null,
          googleMapsApiKey: mapConfig?.provider === "google" ? mapConfig.googleMapsApiKey : null,
        });
      }
    } catch (e) {
      console.error("Error loading client config:", e);
    }

    return () => setState((prev) => ({ ...prev, clientId: null }));
  }, []);

  return state;
};

// Custom hook to get user ID
const useUserId = (currentUser, selectedAppId) => {
  return useCallback(() => {
    const currentAppRoles = currentUser?.client_app_roles;
    const currentUserId = currentUser?.id;
    let hasAdminRole = false;

    currentAppRoles?.forEach((clientData) => {
      if (clientData?.client_app_id === selectedAppId) {
        clientData?.roles?.forEach((role) => {
          if (role?.name === "Admin" || role?.name === "Fleet Admin") {
            hasAdminRole = true;
          }
        });
      }
    });

    return hasAdminRole ? "" : currentUserId;
  }, [currentUser, selectedAppId]);
};

// Custom hook to manage tracking data
const useTrackingData = (sourceIds, deviceId, realTimedata) => {
  const { data: trackingData = { data: [] } } = useGetDevicesTrackingQuery({
    device_ids: sourceIds,
  });
  const [state, setState] = useState({
    mapData: {},
    trackingPoints: [],
    deviceData: null,
  });

  const processTrackingData = useCallback(
    debounce((trackingData, realTimedata) => {
      const filteredPosition = {};
      const points = [];

      trackingData.latest_data?.forEach(({ device_data }) => {
        if (!device_data?.source_id) return;

        const sourceId = device_data.source_id;
        const realTimeDataForSource = realTimedata[sourceId];
        const position = realTimeDataForSource?.gps?.position || device_data?.gps?.v?.position;

        if (!position || position.lat === 0 || position.lng === 0) return;

        const deviceInfo = {
          speed: formatSpeed(
            realTimeDataForSource?.velocity?.speed || device_data?.velocity?.v?.speed
          ),
          direction:
            realTimeDataForSource?.velocity?.direction || device_data?.velocity?.v?.direction,
          ignition: realTimeDataForSource?.ignition ?? device_data?.ignition?.v ?? "Unknown",
          sourceTime:
            realTimeDataForSource?.source_time ||
            device_data?.source_time?.v ||
            device_data?.source_time,
          plate_number: device_data?.plate_number?.v,
          serverTime:
            realTimeDataForSource?.server_time ||
            device_data?.server_time?.v ||
            device_data?.server_time,
        };

        filteredPosition[sourceId] = deviceInfo;
        points.push({ ...position, imei: sourceId, ...deviceInfo });
      });

      setState({
        mapData: filteredPosition,
        trackingPoints: points,
        deviceData:
          trackingData.latest_data?.find(({ device_data }) => device_data?.source_id === deviceId)
            ?.device_data || {},
      });
    }, 3000),
    []
  );

  useEffect(() => {
    if (!trackingData?.latest_data) return;
    processTrackingData(trackingData, realTimedata);
  }, [trackingData, realTimedata, processTrackingData]);

  return state;
};

// Helper function to format speed
const formatSpeed = (speed) => {
  if (speed === 0 || speed) {
    return `${(speed * 3.6).toFixed(1)} km/h`;
  }
  return "Not Available";
};

export const DashBoardContainer = () => {
  // Consolidate related state
  const [mapState, setMapState] = useState({
    mapRef: null,
    mapExpand: false,
    initialLoad: true,
    count: 0,
  });

  const [queryState, setQueryState] = useState({
    page: 0,
    perPage: 1000,
    q: "",
    sourceIds: [],
    deviceId: null,
  });

  const { clientId, currentUser, selectedAppId, mapProvider, mapboxAccessToken, googleMapsApiKey } =
    useClientConfig();
  const getUserId = useUserId(currentUser, selectedAppId);

  const pagination = {
    page: queryState.page,
    perPage: queryState.perPage,
    onPageChange: setQueryState.bind(null, "page"),
    onPerPageChange: setQueryState.bind(null, "perPage"),
  };
  const { data: coreDevices = { data: [], total_count: 0 } } = useGetVehiclesQuery({
    page: queryState.page,
    per_page: queryState.perPage,
    q: queryState.q,
    filter_user_id: getUserId(),
  });

  const queryParams = getUserId()
    ? {
        page: queryState.page,
        per_page: queryState.perPage,
        device_ids: queryState.sourceIds,
        q: queryState.q,
      }
    : { page: queryState.page, per_page: queryState.perPage, q: queryState.q };

  const { data: devicesData = { data: [], total_count: 0 } } = useGetDevicesQuery(queryParams);

  let list_arr;

  useEffect(() => {
    try {
      list_arr = [];
      coreDevices?.data.forEach((element, index) => {
        let x = { className: element.device_name_list + "_cls" };
        list_arr.push({ ...element, ...x });
      });

      let tempDevices = [];

      coreDevices?.data?.map((device) => {
        if (device?.device_name_list) {
          let temp = device?.device_name_list?.split(",");
          tempDevices = temp.concat(tempDevices);
        }
      });

      devicesData?.data?.map((device) => {
        if (device?.device_id) {
          if (!tempDevices?.includes(device?.device_id)) {
            tempDevices.push(device?.device_id);
          }
        }
      });

      setQueryState((prev) => ({ ...prev, sourceIds: tempDevices }));
    } catch (e) {}
  }, [coreDevices.data, devicesData?.data]);

  const { realTimedata } = useWebSocket(String(clientId), "all", "sub_all");
  const { mapData, trackingPoints, deviceData } = useTrackingData(
    queryState.sourceIds,
    queryState.deviceId,
    realTimedata
  );

  //Map tracking
  useEffect(() => {
    const layerName = `test_${mapState.count}`;
    const prevLayer = `test_${mapState.count - 1}`;
    const updatedTrackingPoints = [];
    trackingPoints?.map((point) => {
      if (point?.direction != undefined) {
        updatedTrackingPoints?.push(point);
      }
    });
    if (!mapProvider || mapProvider === "qiMap") {
      if (mapState.mapRef && updatedTrackingPoints.length) {
        mapState.count && mapState.mapRef.removeLayer(prevLayer);
        mapState.mapRef.addLayer({ name: layerName });
        mapState.mapRef.drawTrack({
          layerName: layerName,
          fitWithinView: false,
          trackType: "point",
          data: [{ coordinates: updatedTrackingPoints }],
          style: {
            point: {
              style: (feature) => {
                return new Style({
                  image: new Icon({
                    color: "#fff",
                    crossOrigin: "anonymous",
                    src: locationImg,
                    imgSize: [26, 26],
                    rotation: feature.direction,
                  }),
                  text: new Text({
                    text: feature.plate_number || feature.imei,
                    font: "14px Calibri,sans-serif",
                    fill: new Fill({
                      color: "#000", // Black font
                    }),
                    backgroundFill: new Fill({
                      color: "#fff",
                    }),
                    padding: [5, 10, 5, 10], // Padding inside the box
                    offsetY: -30, // Moves the text box above the marker
                    textAlign: "center", // Aligns the text in the center of the box
                    backgroundStroke: new Stroke({
                      color: "#999", // Border color around the box
                      width: 1, // Border width
                    }),
                  }),
                });
              },
            },
          },
        });

        // Only fit the map on initial load
        if (mapState.initialLoad) {
          mapState.mapRef.performFit(layerName);
          setMapState((prev) => ({ ...prev, initialLoad: false })); // Disable initial load after first fit
        }
        //mapState.mapRef.performFit(layerName);
      } else {
        mapState.mapRef?.removeLayer(`test_${mapState.count - 1}`);
      }
      try {
        //Navigating back and forth from Live Tracking
        queryState.deviceId && showPontOnMap(queryState.deviceId, selectedVehiclePlate);
      } catch (e) {}
    } else if (
      mapState.mapRef &&
      updatedTrackingPoints.length &&
      mapProvider === "mapbox" &&
      mapboxAccessToken
    ) {
      const onStyleLoaded = () => {
        // Register a custom icon
        mapState.mapRef.loadImage(
          "https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png",
          (error, image) => {
            if (error) throw error;

            // Check if the image is already added
            if (!mapState.mapRef.hasImage("custom-icon")) {
              mapState.mapRef.addImage("custom-icon", image);
            }

            // Transform data for Mapbox
            const cleanedData = updatedTrackingPoints.map((item) => ({
              coordinates: [item.lng, item.lat],
              plate_number: item.plate_number,
              direction: item.direction,
            }));

            // Remove the previous layer if it exists
            if (mapState.mapRef.getLayer(layerName)) {
              mapState.mapRef.removeLayer(layerName);
              mapState.mapRef.removeSource(layerName);
            }

            // Add a new GeoJSON source with tracking points
            mapState.mapRef.addSource(layerName, {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: cleanedData.map((point) => ({
                  type: "Feature",
                  geometry: {
                    type: "Point",
                    coordinates: point.coordinates, // [lng, lat]
                  },
                  properties: {
                    plate_number: point.plate_number,
                    direction: point.direction,
                  },
                })),
              },
            });

            // Add a layer for the points
            mapState.mapRef.addLayer({
              id: layerName,
              type: "symbol",
              source: layerName,
              layout: {
                "icon-image": "custom-icon", // Custom icon registered in the map
                "icon-size": 0.6,
                "icon-rotate": ["get", "direction"], // Rotate icon based on direction
                "text-field": ["get", "plate_number"],
                "text-size": 12,
                "text-offset": [0, -1.5], // Move text above the marker
                "text-anchor": "top",
                "text-padding": 10, // Add padding around text (equivalent to padding: 5px 10px)
                "text-allow-overlap": true, // Allow text to overlap with other elements
                "text-justify": "center",
              },
              paint: {
                "text-color": "#000000",
                "text-halo-color": "#ffffff",
                "text-halo-width": 1,
                // Add background styling with border
                "text-opacity": 1,
                "text-halo-blur": 0,
                // Add a white background with border
                "text-color": "#000000",
                "text-halo-color": "#ffffff", // White background
                "text-halo-width": 2, // Background size
                // Unfortunately, Mapbox GL JS doesn't directly support border-radius for text
                // We can add a border by using a different color
                "text-halo-blur": 0.5,
              },
            });

            // Ensure selected vehicle appears on top
            mapState.mapRef.setLayoutProperty(layerName, "icon-allow-overlap", true);
            mapState.mapRef.setPaintProperty(layerName, "icon-opacity", [
              "case",
              ["==", ["get", "plate_number"], selectedVehiclePlate],
              1, // Selected vehicle opacity
              0.6, // Non-selected opacity
            ]);

            // Fit the map to the tracking points on initial load
            if (mapState.initialLoad) {
              const coordinates = cleanedData.map((p) => p.coordinates);
              const bounds = coordinates.reduce(
                (bounds, coord) => bounds.extend(coord),
                new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])
              );

              mapState.mapRef.fitBounds(bounds, { padding: 50, duration: 1000 });
              setMapState((prev) => ({ ...prev, initialLoad: false }));
            }

            // Center on a selected vehicle dynamically
            if (queryState.deviceId && selectedVehiclePlate) {
              const selectedPoint = cleanedData.find(
                (p) => p.plate_number === selectedVehiclePlate
              );
              if (selectedPoint) {
                mapState.mapRef.flyTo({
                  center: selectedPoint.coordinates,
                  zoom: 15,
                  speed: 0.8,
                });
              }
            }
          }
        );
      };

      // Check if the style is already loaded
      if (mapState.mapRef.isStyleLoaded()) {
        onStyleLoaded();
      } else {
        // Wait for the style to load
        mapState.mapRef.on("style.load", onStyleLoaded);
      }
    } else if (
      mapProvider === "google" &&
      mapState.mapRef &&
      updatedTrackingPoints.length &&
      googleMapsApiKey
    ) {
      // For Google Maps
      const bounds = new google.maps.LatLngBounds();

      // Clear previous markers and overlays
      if (window.googleMarkers) {
        window.googleMarkers.forEach((marker) => marker.setMap(null)); // Clear markers
      }
      if (window.googleOverlays) {
        window.googleOverlays.forEach((overlay) => overlay.setMap(null)); // Clear overlays
      }

      // Initialize arrays
      window.googleMarkers = [];
      window.googleOverlays = [];

      // Add markers with styled labels for tracking points
      updatedTrackingPoints.forEach((point) => {
        const position = { lat: point.lat, lng: point.lng };

        // Create the marker
        const marker = new google.maps.Marker({
          position,
          map: mapState.mapRef,
          icon: {
            url: locationImg, // Marker icon
            scaledSize: new google.maps.Size(26, 26),
          },
          zIndex: point.plate_number === selectedVehiclePlate ? 10 : 1, // Selected vehicle on top
        });

        // Custom label styling
        const labelDiv = document.createElement("div");
        labelDiv.style.position = "absolute";
        labelDiv.style.background = "#fff";
        labelDiv.style.color = "#000";
        labelDiv.style.padding = "5px 10px"; // Increased horizontal padding
        labelDiv.style.border = "1px solid #999"; // Changed border color
        labelDiv.style.borderRadius = "4px"; // Added border radius
        labelDiv.style.textAlign = "center";
        labelDiv.style.font = "14px Calibri, sans-serif";
        labelDiv.style.transform = "translate(-50%, -50%)"; // Center the label
        labelDiv.innerText = point.plate_number;

        // Add a custom overlay
        const overlay = new google.maps.OverlayView();
        overlay.onAdd = function () {
          const pane = overlay.getPanes().overlayLayer;
          pane.appendChild(labelDiv);
        };
        overlay.draw = function () {
          const projection = overlay.getProjection();
          const pointPos = projection.fromLatLngToDivPixel(position);
          labelDiv.style.left = `${pointPos.x}px`;
          labelDiv.style.top = `${pointPos.y - 30}px`; // Offset above marker
        };
        overlay.onRemove = function () {
          if (labelDiv.parentNode) {
            labelDiv.parentNode.removeChild(labelDiv);
          }
        };
        overlay.setMap(mapState.mapRef);

        // Extend bounds for auto-fit
        bounds.extend(position);

        // Save marker and overlay for later removal
        window.googleMarkers.push(marker);
        window.googleOverlays.push(overlay);
      });

      // Fit bounds on initial load
      if (mapState.initialLoad) {
        mapState.mapRef.fitBounds(bounds);
        setMapState((prev) => ({ ...prev, initialLoad: false }));
      }

      if (queryState.deviceId && selectedVehiclePlate) {
        const selectedPoint = updatedTrackingPoints.find(
          (p) => p.plate_number === selectedVehiclePlate
        );
        if (selectedPoint) {
          // Center the map on the selected vehicle
          mapState.mapRef.panTo({
            lat: selectedPoint.lat,
            lng: selectedPoint.lng,
          });

          // Set zoom level
          mapState.mapRef.setZoom(15);

          // Optionally, you can animate this using setTimeout for gradual zoom
          mapState.mapRef.panTo({
            lat: selectedPoint.lat,
            lng: selectedPoint.lng,
          });
          setTimeout(() => {
            mapState.mapRef.setZoom(15);
          }, 500); // Adjust delay for smoother animation
        }
      }
    }
    //Increase layer count
    setMapState((prev) => ({ ...prev, count: prev.count + 1 }));
  }, [mapState.mapRef, trackingPoints, queryState.deviceId, mapState.initialLoad, mapProvider]);

  return (
    <>
      <VehicleMap
        mapProvider={mapProvider}
        mapExpand={mapState.mapExpand}
        mapRef={mapState.mapRef}
        setMapRef={(ref) => setMapState((prev) => ({ ...prev, mapRef: ref }))}
        mapboxAccessToken={mapboxAccessToken}
        googleMapsApiKey={googleMapsApiKey}
      />
    </>
  );
};
