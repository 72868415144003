import { useParams } from "react-router-dom";
import { useGetContainersQuery, useGetPluginsQuery } from "../services";
import { MPSharedHeader } from "../Shared";
import { getLocalizedString } from "../../../shared/translation";
import { useEffect, useState } from "react";
import { usePagination } from "../../../hooks";
import { PluginContainer } from "../../Plugins";
import { EditCustomPluginContainer } from "./EditCustomPluginContainer";

export const MPRenderFirstPluginContainer = () => {
  let params = useParams();
  const [showForm, setShowForm] = useState(true);
  const [idToEdit, setIdToEdit] = useState(null);

  const {
    data: newContainers,
    isFetching,
    error,
  } = useGetContainersQuery({ page: 0, per_page: 500 });

  let selectedContainer = newContainers?.data?.find((container) => {
    return container.path === params.path;
  });

  if (!selectedContainer && !isFetching) {
    return (
      <div>
        {getLocalizedString(
          "No container found or invalid path",
          "No container found or invalid path"
        )}
      </div>
    );
  }

  /* const onCreate = () => {
    setShowForm(true);
  };

  const onEdit = (container) => {
    setIdToEdit(container.id);
    setShowForm(true);
  };

  const handleClose = () => {
    setShowForm(false);
  }; */

  const { page, setPage, perPage, pagination } = usePagination();

  const { data = { data: [], total_count: 0 }, isSuccess } = useGetPluginsQuery({
    page,
    per_page: 500,
    order_by: "plugins.created_at",
    order_dir: "desc",
  });

  let selectedPlugin;
  const pluginIndex =
    selectedContainer?.plugin_ids?.findIndex((id) => {
      const plugin = data?.data.find((p) => p.id === id);
      return plugin && plugin.is_enabled !== false;
    }) ?? 0;

  selectedPlugin =
    data?.data.find((plugin) => plugin?.id === selectedContainer?.plugin_ids[pluginIndex]) ||
    data?.data.find((plugin) => plugin?.mp_containers.includes(String(selectedContainer?.id))) ||
    data?.data.find((plugin) => plugin?.tracker_containers.includes(String(selectedContainer?.id)));

  /* if (!selectedPlugin && isSuccess) {
    return (
      <div>
        {getLocalizedString(
          "No plugin found for the selected container or invalid plugin path.",
          "No plugin found for the selected container or invalid plugin path."
        )}
      </div>
    );
  } */

  const strippedName = selectedContainer?.name.replace(/\s+/g, "");

  /* useEffect(() => {
    return () => {
      const url = window.location;
      if (url.pathname.includes("header/plugin")) return;
      url.reload();
    };
  }, []); */

  return (
    <>
      <article>
        <MPSharedHeader
          groupName={strippedName}
          resourceName={`${selectedContainer?.name}`}
          heading={getLocalizedString(`${selectedContainer?.name}`, `${selectedContainer?.name}`)}
          simpleSearchPlaceholder={getLocalizedString("search_by_name", "Search by Name")}
          selectedContainer={selectedContainer}
        />
        <PluginContainer selectedPlugin={selectedPlugin} isSuccess={isSuccess} data={data} />
      </article>
    </>
  );
};
