import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Components from "../../../components";
import {
  useCreateUserMutation,
  useGetUserQuery,
  useUpdateUserMutation,
  useGetAppsQuery,
  useGetUserGroupsQuery,
  useGetLanguagesQuery,
  useGetLayoutsQuery,
} from "../services";
import { mergeObjects } from "../../../shared/helper";
import { FormContainer } from "../Shared";
import { userFormSchema, userEditFormSchema } from "../validations";
import { useForm, useStaticDropDownSearch } from "../../../hooks";
import { SvgIcon } from "../../Shared";
import { userPlaceholder } from "../../../assets";
import { parseServerError } from "../../../shared/helper";
import { FMDPStorage } from "../../../shared/helper";
import Countrycode from "../../../shared/countryCode.json";
import { getLocalizedString } from "../../../shared/translation";
import { useDropDownSearch } from "../../../hooks";

export const UserForm = ({ idToEdit = null, onClose }) => {
  const dropdownParams = { per_page: 1000, order_by: "name" };
  const [initialValues, setInitialValues] = useState({
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    external_driver_rfid: "",
    // driving_licence: "",
    // driving_licence_validity: "",
    // driver_description: "",
    roles: [],
    image: "",
    is_enabled: true,
    country_code: "",
    phone_no: "",
    user_group_ids: [],
    locale_id: "",
    layout_id: "",
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: idToEdit ? userEditFormSchema : userFormSchema,
    onSubmit: (values) => handleFormSubmit(values),
  });

  // Get the langauges list using Dropdown Search
  const {
    data: languages,
    setSearchKey: setLanguagesSearch,
    isSuccess: isLanguagesSuccess,
  } = useDropDownSearch({
    useData: useGetLanguagesQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.locale_id,
    additionalParams: { ...dropdownParams },
  });

  // Get the layouts list
  const { data: layouts, isSuccess: isLayoutsSuccess } = useDropDownSearch({
    useData: useGetLayoutsQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.layout_id,
  });

  const {
    create,
    update,
    error: updateError,
    updateSuccess,
    resetUpdate,
  } = useForm({
    createMutation: useCreateUserMutation,
    updateMutation: useUpdateUserMutation,
    closeForm: onClose,
    onSuccess: () => {
      const currentUser = FMDPStorage.get("current-user");
      if (currentUser.id == idToEdit) {
        //window.location.reload();
      }
    },
    setError: formik.setErrors,
  });

  const { data } = useGetUserQuery({ id: idToEdit }, { skip: !idToEdit });

  const { data: apps = { data: [] }, isSuccess: isAppsSuccess } = useGetAppsQuery({
    include: "roles",
  });

  // Get the country code list using Dropdown Search for static data
  const { data: countryCode, setSearchKey: setCountryCodeSearchKey } = useStaticDropDownSearch({
    dataSet: Countrycode,
    selectedIds: formik.values.country_code,
  });

  const { data: userGroups, isSuccess: isuserGroupsSuccess } =
    useGetUserGroupsQuery(dropdownParams);

  const handleRoleSelection = (roleId) => {
    const tempFromData = { ...formik.values };
    let index = tempFromData.roles.findIndex((id) => id == roleId);

    if (index >= 0) {
      tempFromData.roles = tempFromData.roles.filter((id) => id != roleId);
    } else {
      tempFromData.roles = [...tempFromData.roles, roleId];
    }

    formik.setValues(tempFromData);
  };

  // Set form data in case of edit
  useEffect(() => {
    if (idToEdit && data) {
      setInitialValues(mergeObjects(initialValues, data));
    }
  }, [data]);

  useEffect(() => {
    if (updateError) {
      formik.setErrors(parseServerError(updateError));
    }
  }, [updateError]);

  // On Update Success Log-Out
  useEffect(() => {
    const prevRoles = data?.roles;
    const updatedRole = formik.getFieldProps("roles").value;
    const { id } = JSON.parse(localStorage.getItem("current-user"));
    if (
      updateSuccess &&
      idToEdit === id &&
      (JSON.stringify(updatedRole) !== JSON.stringify(prevRoles) ||
        data?.is_enabled !== formik.getFieldProps("is_enabled").value)
    ) {
      FMDPStorage.clear();
      window.location.reload();
    }
  }, [updateSuccess, data]);

  useEffect(() => {
    if (updateSuccess) {
      const selectedLanguage = languages?.data.find((lan) => lan?.id == formik?.values.locale_id);
      const currentUser = FMDPStorage.get("current-user");
      const updatedUser = { ...currentUser, locale: selectedLanguage };
      FMDPStorage.set("current-user", updatedUser);
      window.location.reload();
    }
  }, [updateSuccess]);

  const handleFormSubmit = (formData) => {
    let fObj = new FormData();

    Object.keys(formData).forEach((key) => {
      if (key == "image" && formData.image == null) {
        fObj.append("remove_image", true);
      } else if (!(key == "image" && formData.image == "") && formData[key] != null) {
        if (!idToEdit || (idToEdit && key !== "password")) {
          if (Array?.isArray(formData[key])) {
            // Convert array of strings to array of integers
            const arrayOfIntegers = formData[key]?.map((value) => parseInt(value));
            // Append the array of integers
            fObj?.append(key, JSON.stringify(arrayOfIntegers));
          } else {
            fObj?.append(key, formData[key]);
          }
        }
      }
    });

    if (idToEdit) {
      update({ formData: fObj, id: idToEdit });
    } else {
      create({ formData: fObj });
    }
  };

  return (
    <FormContainer
      resourceName={getLocalizedString("user", "User")}
      closeForm={() => onClose(false)}
      handleFormSubmit={formik.handleSubmit}
      idToEdit={idToEdit}
    >
      <Components.QIPhotoUploader
        label={getLocalizedString("upload_image", "Upload Image")}
        src={formik.values.image}
        onChange={(files) => formik.setFieldValue("image", files?.length > 0 ? files[0] : null)}
        showJustImage
        base64={false}
        initalImageUrl={formik.values.image}
        placeHolderImg={userPlaceholder}
        photoGuide={`
        <h4 className="font-semibold">${getLocalizedString(
          "image_upload_guide",
          "Image Upload guide"
        )} - </h4>
        <div className="ml-2">
          <p>${getLocalizedString("max_size", "Max size")} 40kb</p>
          <p>${getLocalizedString("file_format", "File format")} .jpg/ .jpeg/ .png/ .gif</p>
          <p>${getLocalizedString(
            "preferred_image_aspect_ratio",
            "Preferred image aspect ratio"
          )} 1:1</p>
        </div>
      `}
        error={formik.touched.image && formik.errors.image}
      />
      <Components.QIInput
        label={getLocalizedString("first_name", "First Name")}
        {...formik.getFieldProps("first_name")}
        error={formik.touched.first_name && formik.errors.first_name}
      />
      <Components.QIInput
        label={getLocalizedString("last_name", "Last Name")}
        {...formik.getFieldProps("last_name")}
        error={formik.touched.last_name && formik.errors.last_name}
      />
      <Components.QIInput
        label={getLocalizedString("rfid", "ID/RFID")}
        {...formik.getFieldProps("external_driver_rfid")}
        error={formik.touched.external_driver_rfid && formik.errors.external_driver_rfid}
      />
      <div className="flex justify-between country-code-and-number">
        <Components.QICustomSelect
          label={getLocalizedString("country_code", "Country Code")}
          className="country-code-and-number_item1"
          {...formik.getFieldProps("country_code")}
          onChange={(value) => formik.setFieldValue("country_code", value)}
          onSearch={setCountryCodeSearchKey}
          error={formik.touched.country_code && formik.errors.country_code}
          labelClassName="country-code"
        >
          <li value="">{getLocalizedString("select", "Select")}</li>
          {countryCode?.map((code, index) => (
            <li key={index} value={code.dial_code} title={`${code.dial_code} (${code.name})`}>
              {code.dial_code} ({code.name})
            </li>
          ))}
        </Components.QICustomSelect>

        <Components.QIInput
          label={getLocalizedString("phone_no", "Phone No.")}
          placeholder={getLocalizedString("phone_no", "Phone No.")}
          className="country-code-and-number_item2"
          customLabelClass="phone-number"
          customWrapperClass="phone-number-wrapper"
          {...formik.getFieldProps("phone_no")}
          error={formik.touched.phone_no && formik.errors.phone_no}
        />
      </div>
      <Components.QIInput
        label={getLocalizedString("email", "Email")}
        {...formik.getFieldProps("email")}
        error={formik.touched.email && formik.errors.email}
        disabled={idToEdit}
      />
      <Components.QICustomSelect
        label={getLocalizedString("preferred_language", "Preferred Language")}
        {...formik.getFieldProps("locale_id")}
        onChange={(value) => {
          formik.setFieldValue("locale_id", value);
          setLanguagesSearch("");
        }}
        error={formik.touched.locale_id && formik.errors.locale_id}
        labelClassName="preferred-language"
        onSearch={setLanguagesSearch}
      >
        <>
          {languages?.data?.map((lan, index) => (
            <li value={lan.id} key={index}>
              {lan.name}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("preferred_layout", "Preferred Layout")}
        {...formik.getFieldProps("layout_id")}
        onChange={(value) => {
          formik.setFieldValue("layout_id", value);
        }}
        error={formik.touched.layout_id && formik.errors.layout_id}
        labelClassName="preferred-layout"
      >
        <>
          {layouts?.data?.map((layout, index) => (
            <li value={layout.id} key={index}>
              {layout.name}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      {/*
      <Components.QIInput
        label="Driving Licence"
        {...formik.getFieldProps("driving_licence")}
        error={formik.touched.driving_licence && formik.errors.driving_licence}
        disabled={true}
      />
      <Components.QIInput
        label="Driver Licence Validity"
        {...formik.getFieldProps("driving_licence_validity")}
        error={formik.touched.driving_licence_validity && formik.errors.driving_licence_validity}
        disabled={true}
      />
      <Components.QIInput
        label="Driver Description"
        textarea
        {...formik.getFieldProps("driver_description")}
        error={formik.touched.driver_description && formik.errors.driver_description}
        disabled={true}
      /> */}
      <Components.QISwitch
        className="active-status"
        label={getLocalizedString("active", "Active")}
        value={formik.values.is_enabled}
        readOnly={JSON.parse(localStorage.getItem("current-user"))?.email == formik?.values?.email}
        disabled={JSON.parse(localStorage.getItem("current-user"))?.email == formik?.values?.email}
        onChange={() => {
          formik.setFieldValue("is_enabled", !formik.values.is_enabled);
        }}
        error={formik.touched.is_enabled && formik.errors.is_enabled}
      />
      {!idToEdit && (
        <Components.QIInput
          label={getLocalizedString("password", "Password")}
          {...formik.getFieldProps("password")}
          error={formik.touched.password && formik.errors.password}
        />
      )}
      <div className={`qi-input ${formik.touched.roles && formik.errors.roles && "error"}`}>
        <label className="qi-input_label">
          {getLocalizedString("applications", "Applications")}
        </label>
        <div className="qi-input_wrapper">
          <ul className="list-unstyled">
            {isAppsSuccess &&
              apps?.data?.map((app, index) => (
                <li key={index} className="roles-and-applications-set">
                  <h4 className="title">{app.name}</h4>
                  <ul className="roles-and-applications-set_list">
                    {app?.client_app_roles?.map((role, index) => (
                      <Components.QICheckBox
                        label={role.name}
                        id={`${role.id}`}
                        checked={formik.values.roles.includes(role.id)}
                        onChange={() => handleRoleSelection(role.id)}
                        value={role.id || ""}
                        className=""
                        key={index}
                      />
                    ))}
                  </ul>
                </li>
              ))}
          </ul>
          {formik.touched.roles && formik.errors.roles && (
            <div className="qi-input_error">
              <SvgIcon name="info" />
              <p className="qi-input_error_message ">{formik.errors.roles}</p>
            </div>
          )}
        </div>
      </div>
      <Components.QIMultiSelectDropDown
        label={getLocalizedString("group", "Group")}
        selected={formik.values.user_group_ids}
        onChange={(value) => formik.setFieldValue("user_group_ids", value)}
        data={userGroups?.data || []}
        className="narrow-multiselect-dropdown"
        readOnly={formik.values.roles.length > 0 ? false : true}
      />
    </FormContainer>
  );
};
