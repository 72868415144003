import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { FormContainer } from "../Shared/FormContainer";
import * as Components from "../../../components";
import moment from "moment";
import { useDropDownSearch, useForm } from "../../../hooks";
import {
  useCreateResellerMonthlyReportMutation,
  useGetResellerMonthlyReportQuery,
  useUpdateResellerMonthlyReportMutation,
} from "../services";
import { getLocalizedString } from "../../../shared/translation";
import { reportTypes, subTypes, summaryIntervals } from "./static";
import { months } from "../../../shared/dropdownLists";

export const ReportsForm = ({ onClose }) => {
  const [resetCount, setResetCount] = useState(0);
  const [selectedRange, setSelectedRange] = useState({
    startDate: moment().subtract(24, "hours"),
    endDate: moment(),
  });
  const [initialValues, setInitalValues] = useState({
    name: "",
    report_type: "",
    report_sub_type: "",
    summary_interval: "",
    metadata: {
      start_month: null,
      end_month: null,
      start_year: null,
      end_year: null,
    },
  });

  // initialize Formik
  const formik = useFormik({
    initialValues,
    //validationSchema: reportFormValidation,
    enableReinitialize: true,
    onSubmit: (values) => handleFormSubmit(values),
  });

  const { create: createReport, update: updateReport } = useForm({
    createMutation: useCreateResellerMonthlyReportMutation,
    updateMutation: useUpdateResellerMonthlyReportMutation,
    closeForm: onClose,
    setError: formik.setErrors,
  });

  const handleFormSubmit = (formdata) => {
    // Extract metadata from selectedRange
    const metadata = {
      start_month: selectedRange.startDate.month() + 1, // moment months are 0-indexed
      end_month: selectedRange.endDate.month() + 1,
      start_year: selectedRange.startDate.year(),
      end_year: selectedRange.endDate.year(),
    };

    createReport({
      formData: {
        ...formdata,
        metadata,
      },
    });
  };

  // Add useEffect to update metadata when date range changes
  useEffect(() => {
    formik.setFieldValue("metadata", {
      start_month: selectedRange.startDate.month() + 1,
      end_month: selectedRange.endDate.month() + 1,
      start_year: selectedRange.startDate.year(),
      end_year: selectedRange.endDate.year(),
    });
  }, [selectedRange]);

  return (
    <>
      <FormContainer
        customResourceName={getLocalizedString("report", "Report")}
        handleFormSubmit={formik.handleSubmit}
        closeForm={onClose}
        //ifTrackingApp={true}
      >
        <Components.QIInput
          label={getLocalizedString("name", "Name")}
          {...formik.getFieldProps("name")}
          // error={formik.touched.name && formik.errors.name}
        />

        {/* Report Type */}
        <Components.QICustomSelect
          label={getLocalizedString("type", "Type")}
          value={formik?.values?.report_type}
          onChange={(value) => formik.setFieldValue("report_type", value)}
        >
          {reportTypes?.map((type, index) => (
            <li value={type.id} key={index}>
              {getLocalizedString(type?.localization_key) || type?.name}
            </li>
          ))}
        </Components.QICustomSelect>

        {/* Report SubType */}
        <Components.QICustomSelect
          label={getLocalizedString("subtype", "Sub-Type")}
          value={formik?.values?.report_sub_type}
          onChange={(value) => {
            formik.setFieldValue("report_sub_type", value);
          }}
          //error={formik?.touched?.report_sub_type && formik?.errors?.report_sub_type}
        >
          {subTypes?.map((type, index) => (
            <li value={type.id} key={index}>
              {getLocalizedString(type?.localization_key) || type?.name}
            </li>
          ))}
        </Components.QICustomSelect>

        {/* Report Summary Interval */}
        {formik?.values?.report_sub_type === "summary" && (
          <Components.QICustomSelect
            label={getLocalizedString("summary_interval", "Summary Interval")}
            value={formik?.values?.summary_interval}
            onChange={(value) => {
              formik.setFieldValue("summary_interval", value);
            }}
            //error={formik?.touched?.summary_interval && formik?.errors?.summary_interval}
          >
            {summaryIntervals?.map((type, index) => (
              <li value={type.id} key={index}>
                {getLocalizedString(type?.localization_key) || type?.name}
              </li>
            ))}
          </Components.QICustomSelect>
        )}

        {/* Month Selection */}
        <div className="flex gap-4 w-full">
          <div className="w-1/2">
            <Components.QICustomSelect
              label={getLocalizedString("start_month", "Start Month")}
              value={formik?.values?.metadata?.start_month}
              onChange={(value) => {
                const newStartDate = selectedRange.startDate.clone().month(value - 1);
                setSelectedRange((prev) => ({
                  ...prev,
                  startDate: newStartDate,
                }));
              }}
            >
              {months?.map((month, index) => (
                <li value={month.value} key={index}>
                  {getLocalizedString(month?.localization_key) || month?.name}
                </li>
              ))}
            </Components.QICustomSelect>
          </div>

          <div className="w-1/2">
            <Components.QICustomSelect
              label={getLocalizedString("end_month", "End Month")}
              value={formik?.values?.metadata?.end_month}
              onChange={(value) => {
                const newEndDate = selectedRange.endDate.clone().month(value - 1);
                setSelectedRange((prev) => ({
                  ...prev,
                  endDate: newEndDate,
                }));
              }}
            >
              {months?.map((month, index) => (
                <li value={month.value} key={index}>
                  {getLocalizedString(month?.localization_key) || month?.name}
                </li>
              ))}
            </Components.QICustomSelect>
          </div>
        </div>
      </FormContainer>
    </>
  );
};
