import React, { useEffect, useState, useRef } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { locationImg } from "../assets";
import {
  useGetEventConfigurationQuery,
  useGetGeofenceQuery,
} from "../../ManagementPortal/services";
import { getLocalizedString } from "../../../shared/translation";

const EventsMap = ({
  deviceId,
  mapPoint,
  mapExpand,
  dockAlign,
  selectedRowId,
  showEventsDetails,
  setDockAlign,
  maximizeMinimize,
  mapProvider,
  mapboxAccessToken,
  googleMapsApiKey,
}) => {
  const [map, setMap] = useState(null);
  const [geofenceData, setGeofenceData] = useState({});
  const countRef = useRef(0);
  const markerRef = useRef(null);
  const circleRef = useRef(null);
  const polygonRef = useRef(null);
  const mapContainerRef = useRef(null);

  // Get Geofence Data
  const { data: planData } = useGetEventConfigurationQuery(
    showEventsDetails?.events?.config_id && {
      id: showEventsDetails?.events?.config_id,
    }
  );

  const { data: geoResponse } = useGetGeofenceQuery(
    planData?.geofence_id && { id: planData?.geofence_id }
  );

  useEffect(() => {
    if (geoResponse?.json_data) {
      setGeofenceData(JSON.parse(geoResponse?.json_data));
    }
  }, [geoResponse]);

  useEffect(() => {
    // Initialize the map with dynamic tile layer based on map provider
    const mapInstance = L.map("device-map-container", {
      center: [0, 0],
      zoom: 1,
      minZoom: 2,
      // Adjust maxZoom based on provider
      maxZoom: mapProvider === "google" ? 20 : mapProvider === "mapbox" ? 22 : 19,
    });

    // Select tile layer based on map provider
    let tileLayer;
    switch (mapProvider) {
      case "mapbox":
        if (mapboxAccessToken) {
          tileLayer = L.tileLayer(
            `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${mapboxAccessToken}`,
            {
              attribution: "© Mapbox",
              tileSize: 512,
              zoomOffset: -1,
              maxZoom: 22,
              errorTileUrl:
                "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7", // Transparent tile for errors
            }
          );
        }
        break;
      case "google":
        if (googleMapsApiKey) {
          tileLayer = L.tileLayer(
            `https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&key=${googleMapsApiKey}`,
            {
              attribution: "© Google Maps",
              maxZoom: 20,
              errorTileUrl:
                "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
            }
          );
        }
        break;
      default:
        // Default to OpenStreetMap for qiMap
        tileLayer = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution: "© OpenStreetMap contributors",
          maxZoom: 19,
          errorTileUrl:
            "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
        });
    }

    // Add tile layer if created
    if (tileLayer) {
      tileLayer.addTo(mapInstance);
    }

    setMap(mapInstance);

    // Clean up on component unmount
    return () => {
      mapInstance.remove();
    };
  }, [mapProvider, mapboxAccessToken, googleMapsApiKey]);

  useEffect(() => {
    try {
      if (mapPoint && deviceId && (selectedRowId || selectedRowId === 0)) {
        // Remove previous layers
        if (markerRef?.current) map.removeLayer(markerRef?.current);
        if (circleRef?.current) map.removeLayer(circleRef?.current);
        if (polygonRef?.current) map.removeLayer(polygonRef?.current);

        // Draw Track Marker
        const trackMarker = L?.marker(mapPoint, {
          icon: L?.icon({
            iconUrl: locationImg,
            iconSize: [38, 38],
          }),
          name: `track_${countRef?.current}`,
        })?.addTo(map);
        markerRef.current = trackMarker;

        trackMarker?.bindTooltip(() => {
          return `<div>${getLocalizedString("latitude", "Latitude")}: ${Number(
            mapPoint?.lat
          ).toFixed(5)}, ${getLocalizedString("longitude", "Longitude")}: ${Number(
            mapPoint?.lng
          ).toFixed(5)} </div>`;
        });

        // Draw Circle
        if (showEventsDetails?.events?.type === "geofence") {
          // Draw Circle only if the event type is "geofence"
          const coordinates =
            Object.keys(geofenceData).length > 0 && geofenceData?.coordinates
              ? geofenceData?.coordinates[0]?.map((cord) => [cord[1], cord[0]])
              : [];
          if (geofenceData?.type === "Circle") {
            const circle = L.circle(coordinates, {
              radius: 500,
              color: "green",
              weight: 4,
              fillOpacity: 0.4,
              name: `circle_${countRef.current}`,
            }).addTo(map);
            circleRef.current = circle;
          } else if (geofenceData?.type === "Polygon") {
            const polygon = L.polygon(coordinates, {
              color: "green",
              weight: 4,
              fillOpacity: 0.4,
              name: `polygon_${countRef.current}`,
            }).addTo(map);
            polygonRef.current = polygon;
          }
        }
        // Set the view to the exact point
        map.setView(mapPoint, 14); // Adjust the zoom level as needed

        countRef.current += 1;
      } else {
        // If mapPoint, deviceId, and selectedRowId are null, set map to default view and remove previous layers
        map?.setView([0, 0], 1);
        if (markerRef.current) map.removeLayer(markerRef.current);
        if (circleRef.current) map.removeLayer(circleRef.current);
        if (polygonRef.current) map.removeLayer(polygonRef.current);
      }
    } catch (e) {
      console.log("Error:", e);
    }
  }, [
    map,
    deviceId,
    mapPoint,
    selectedRowId,
    dockAlign,
    mapExpand,
    showEventsDetails,
    geofenceData,
    setDockAlign,
  ]);

  // Update map size and take up the whole space when dockAlign or mapExpand changes
  useEffect(() => {
    if (map && mapContainerRef?.current) {
      try {
        map.invalidateSize();
      } catch (e) {
        console.log("Error:", e);
      }
    }
  }, [map, dockAlign, mapExpand, maximizeMinimize]);

  return <div className={`map-container`} id="device-map-container" ref={mapContainerRef}></div>;
};

export default EventsMap;
