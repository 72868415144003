import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Components from "../../../components";
import { FormContainer } from "../Shared";
import { useCreateVehicleGroupCsvMutation, useUpdateVehicleGroupCsvMutation } from "../services";
import { useForm } from "../../../hooks";
import { VehicleGroupCsvUploadFormSchema } from "../validations";
import { getCsvDetails } from "../../../shared/helper";
import { useGetVehicleGroupsQuery } from "../services";
import { getLocalizedString } from "../../../shared/translation";

export const VehicleGroupCsvUploadForm = ({ idToEditOrCsvUpload = null, onClose }) => {
  const dropdownParams = { per_page: 1000, order_by: "name" };
  const [initialValues, setInitialValues] = useState({
    csv_file: null,
    update_if_exist: false,
    group_id: "",
    csv_column_mappings: {
      manufacturer: "",
      model: "",
      specification: "",
      name: "",
      plate_number: "",
      engine_number: "",
      chasis_number: "",
      device_ids: "",
      user_email: "",
      driver_email: "",
    },
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VehicleGroupCsvUploadFormSchema,
    onSubmit: (value) => {
      handleFormSubmit(value);
    },
  });

  const [headers, setHeaders] = useState([]);
  const [selectedHeaders, setSelectedHeaders] = useState([]);
  const [isCsvUpload, setIsCsvUpload] = useState(false);

  useEffect(() => {
    let tempArray = [];
    Object.keys(formik.values.csv_column_mappings).map((key) => {
      if (formik.values.csv_column_mappings[key]) {
        tempArray.push(formik.values.csv_column_mappings[key]);
      }
    });
    setSelectedHeaders(tempArray);
  }, [formik.values.csv_column_mappings]);

  useEffect(() => {
    if (formik.values.csv_file) {
      getCsvDetails(formik.values.csv_file, updateCsvDetails);
    }
  }, [formik.values.csv_file]);

  //To set the vehicle group when asset is imported for a particular vehicle group
  useEffect(() => {
    if (idToEditOrCsvUpload) {
      formik.setFieldValue("group_id", idToEditOrCsvUpload);
    }
  }, [idToEditOrCsvUpload]);

  const { create: createVehicleGroupCsv } = useForm({
    createMutation: useCreateVehicleGroupCsvMutation,
    updateMutation: useUpdateVehicleGroupCsvMutation,
    closeForm: onClose,
    setError: formik.setErrors,
  });

  const { data: vehicleGroups, isSuccess: isVehicleGroupsSuccess } =
    useGetVehicleGroupsQuery(dropdownParams);

  const handleFormSubmit = (formData) => {
    if (idToEditOrCsvUpload === null) {
      idToEditOrCsvUpload = formik.values.group_id;
    }
    const formObj = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key === "csv_column_mappings") {
        formObj.append(key, JSON.stringify(formData[key]));
      } else {
        if (formData[key] !== null) {
          formObj.append(key, formData[key]);
        }
      }
    });
    createVehicleGroupCsv({ id: idToEditOrCsvUpload, formData: formObj });
  };

  const updateCsvDetails = (headers, csvSize) => {
    setHeaders(headers);
    setIsCsvUpload(true);
  };

  const showWarningMessage = (value) => {
    let countValueOccurence = 0;
    selectedHeaders.map((header) => {
      if (header === value) {
        countValueOccurence++;
      }
    });
    if (countValueOccurence > 1) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <FormContainer
      closeForm={onClose}
      handleFormSubmit={formik.handleSubmit}
      customResourceName="Import Vehicles"
    >
      <Components.QICustomSelect
        label={getLocalizedString("group", "Group")}
        {...formik.getFieldProps("group_id")}
        onChange={(value) => {
          formik.setFieldValue("group_id", value);
        }}
        error={formik?.touched?.group_id && formik?.errors?.group_id}
        disabled={idToEditOrCsvUpload ? true : false}
        labelClassName="group"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {vehicleGroups?.data?.map((group, index) => (
            <li value={group?.id} key={index}>
              {group?.name}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QIFileUploader
        label={getLocalizedString("import_vehicles", "Import Vehicles")}
        placeholder={getLocalizedString("only_csv_file", "(*Only .csv file)")}
        value={formik.values.csv_file}
        onChange={(files) => formik.setFieldValue("csv_file", files[0])}
        error={formik.touched.csv_file && formik.errors.csv_file}
      />

      <Components.QISwitch
        label={getLocalizedString("update_if_exists", "Update if exists")}
        {...formik.getFieldProps("update_if_exist")}
        error={formik.touched.update_if_exist && formik.errors.update_if_exist}
        onChange={() => formik.setFieldValue("update_if_exist", !formik.values.update_if_exist)}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        readOnly={!formik.values.csv_file || formik.errors.csv_file}
      />

      <Components.QICustomSelect
        label={getLocalizedString("manufacturer", "Manufacturer")}
        {...formik.getFieldProps("csv_column_mappings.manufacturer")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.manufacturer", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.manufacturer &&
          formik?.errors?.csv_column_mappings?.manufacturer
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.manufacturer)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="manufacturer"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("model", "Model")}
        {...formik.getFieldProps("csv_column_mappings.model")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.model", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.model && formik?.errors?.csv_column_mappings?.model
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.model)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="model"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("specification", "Specification")}
        {...formik.getFieldProps("csv_column_mappings.specification")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.specification", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.specification &&
          formik?.errors?.csv_column_mappings?.specification
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.specification)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="specifications"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("name", "Name")}
        {...formik.getFieldProps("csv_column_mappings.name")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.name", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.name && formik?.errors?.csv_column_mappings?.name
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.name)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="name"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("plate_no", "Plate No.")}
        {...formik.getFieldProps("csv_column_mappings.plate_number")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.plate_number", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.plate_number &&
          formik?.errors?.csv_column_mappings?.plate_number
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.plate_number)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="plate-no"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("engine_number", "Engine Number")}
        {...formik.getFieldProps("csv_column_mappings.engine_number")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.engine_number", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.engine_number &&
          formik?.errors?.csv_column_mappings?.engine_number
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.engine_number)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="engine-no"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("chasis_number", "Chassis Number")}
        {...formik.getFieldProps("csv_column_mappings.chasis_number")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.chasis_number", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.chasis_number &&
          formik?.errors?.csv_column_mappings?.chasis_number
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.chasis_number)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="engine-no"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("devices", "Devices")}
        {...formik.getFieldProps("csv_column_mappings.device_ids")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.device_ids", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.device_ids &&
          formik?.errors?.csv_column_mappings?.device_ids
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.device_ids)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="devices"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("user_email", "User Email")}
        {...formik.getFieldProps("csv_column_mappings.user_email")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.user_email", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.user_email &&
          formik?.errors?.csv_column_mappings?.user_email
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.user_email)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="email"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("driver_email", "Driver Email")}
        {...formik.getFieldProps("csv_column_mappings.driver_email")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.driver_email", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.driver_email &&
          formik?.errors?.csv_column_mappings?.driver_email
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.driver_email)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="email"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>
    </FormContainer>
  );
};
