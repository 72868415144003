import { useRef, useState } from "react";
import { SvgIcon } from "../../Shared";

export const HorizontalScroll = ({ className, children }) => {
  // Horizontal scroll for responsive UI
  const elementRef = useRef(null);

  // Visibility for left arrow
  const [arrowVisibleLeft, setArrowVisibleLeft] = useState(true);

  // Visibility for right arrow
  const [arrowVisibleRight, setArrowVisibleRight] = useState(false);

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);

      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowVisibleLeft(true);
      } else {
        setArrowVisibleLeft(false);
      }
      if (
        Math.trunc(element.scrollWidth - element.clientWidth) === Math.trunc(element.scrollLeft)
      ) {
        setArrowVisibleRight(true);
      } else {
        setArrowVisibleRight(false);
      }
    }, speed);
  };

  return (
    <>
      <nav className={`main-container_header_link ${className}`}>
        <span className="qi-slide-select">
          {!arrowVisibleLeft && (
            <div className="tab-slider">
              <SvgIcon
                name="up-arrow"
                wrapperClass="previous"
                onClick={() => {
                  handleHorizantalScroll(elementRef.current, 25, 100, -10);
                }}
              />
            </div>
          )}
        </span>
        <div class="scroll-wrapper" ref={elementRef}>
          {children}
        </div>

        <span className="qi-slide-select">
          {!arrowVisibleRight && (
            <div className="tab-slider">
              <SvgIcon
                name="up-arrow"
                wrapperClass="previous"
                svgClass="rotate-180"
                onClick={() => {
                  handleHorizantalScroll(elementRef.current, 25, 100, 10);
                }}
              />
            </div>
          )}
        </span>
      </nav>
    </>
  );
};
