import React from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { CommonHeader } from "./Shared";
import { SvgIcon } from "../Shared/";
import { NativeSvgIcon } from "./Shared/";

export const AdminLayout = () => {
  const location = useLocation();

  // Main navigation
  const leftPanelLinks = [
    { label: "dashboard", path: "", icon: "dashboard" },
    {
      label: "Devices",
      path: "devices",
      icon: "device",
      subNavigation: [
        { label: "Devices", path: "devices" },
        { label: "Models", path: "devices/models" },
        { label: "Manufacturers", path: "devices/manufacturers" },
      ],
    },
    {
      label: "Vehicles",
      path: "vehicles",
      icon: "vehicle",
      subNavigation: [
        { label: "Vehicles", path: "vehicles" },
        {
          label: "Specifications",
          path: "vehicles/specifications",
        },
        { label: "Variants", path: "vehicles/variants" },
        {
          label: "Models",
          path: "vehicles/manufacturer_models",
        },
        {
          label: "Manufacturers",
          path: "vehicles/manufacturers",
        },
        { label: "Classes", path: "vehicles/classes" },
        { label: "Types", path: "vehicles/types" },
      ],
    },
    {
      label: "Configurations",
      path: "others",
      icon: "undefined",
      subNavigation: [
        {
          label: "Variables",
          path: "others/variables",
        },
        {
          label: "Variable Mappings",
          path: "others/variable_mappings",
        },
        {
          label: "Backends",
          path: "others/backends",
        },
        {
          label: "Geocoders",
          path: "others/geocoders",
        },
        {
          label: "Geolocation Plans",
          path: "others/geolocation_plan",
        },
        {
          label: "OBD Codes",
          path: "others/obd_codes",
        },
        {
          label: "Batch Commands",
          path: "others/batch_command",
        },
        {
          label: "Mobile Service Providers",
          path: "others/msp",
        },
        {
          label: "Localizations",
          path: "others/localization",
        },
        {
          label: "Commands",
          path: "others/commands",
        },
        {
          label: "Display",
          path: "others/display",
        },
      ],
    },
    { label: "Clients", path: "clients", icon: "client" },
    { label: "Admin Users", path: "users", icon: "user" },
    { label: "Audit Trail", path: "audit-trail", icon: "audit-log" },
  ];

  const containerClass = () => {
    return location.pathname.split("/").slice(-1).pop().replaceAll("_", "-");
  };

  return (
    <>
      <CommonHeader appName="Management Portal" />
      <div className={`admin-dashboard flex ${containerClass()}`}>
        <aside className="qi-nav-bar primary left">
          <nav className="qi-nav-bar_navigation">
            <ul className="qi-nav-bar_navigation_sub-nav">
              {leftPanelLinks.map((section, index) => (
                <li className="qi-nav-bar_navigation_sub-nav_item">
                  <NavLink
                    key={index}
                    to={`/superadmin/${section.path}`}
                    className={`qi-nav-bar_navigation_sub-nav_item_link ${
                      section?.subNavigation?.length > 0 ? "" : "visible"
                    }`}
                  >
                    <NativeSvgIcon name={`${section.icon}`} />
                    {section?.subNavigation?.length == undefined && (
                      <div className="qi-nav-bar_navigation_sub-nav_item_info">
                        <span className="">{section.label}</span>
                      </div>
                    )}
                  </NavLink>

                  {section?.subNavigation?.length > 0 && (
                    <ul className="qi-nav-bar_navigation_sub-nav_nesting-nav">
                      {section?.subNavigation?.map((subNav) => (
                        <li className="qi-nav-bar_navigation_sub-nav_nesting-nav_item">
                          <NavLink
                            to={`/superadmin/${subNav.path}`}
                            className={`qi-nav-bar_navigation_sub-nav_nesting-nav_item_link`}
                            key={index}
                          >
                            <span className="">{subNav.label}</span>
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>

          <div className="copyright">
            <span>&copy;</span>
            <p className="copyright_info">
              Copyright &copy; {new Date().getFullYear()} Fleet Management. All rights reserved.
            </p>
          </div>
        </aside>

        <main className="tab-content grow">
          <Outlet />
        </main>
      </div>
    </>
  );
};
