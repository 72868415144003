export const subTypes = [
  {
    id: "summary",
    localization_key: "summary",
    name: "Summary",
  },
];

export const reportTypes = [
  {
    id: "device",
    localization_key: "device",
    name: "Device",
  },
];

export const summaryIntervals = [
  {
    id: "monthly",
    localization_key: "monthly",
    name: "Monthly",
  },
];
