import React, { useState } from "react";
import { MPSharedTable, MPSharedDeleteModal, MPSharedHeader, TableDropDownView } from "../Shared";
import { usePagination, useDelete, useSearchQuery } from "../../../hooks";
import { useGetSimCardsQuery, useDeleteSimCardMutation } from "../services";
import { SimCardsForm } from "./form";
import { TickOrCross } from "../../Shared";
import { getLocalizedString } from "../../../shared/translation";

export const SimCardsContainer = () => {
  const [idToDelete, setIdToDelete] = useState(null);
  const [idToEdit, setIdToEdit] = useState(null);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const { page, setPage, perPage, pagination } = usePagination();
  const [setUpdated] = useState(false);

  const { setSimpleSearch, simpleSearch } = useSearchQuery({
    page,
    setPage,
  });

  const {
    data = {
      total_count: 0,
      data: [],
    },
    error,
    isFetching,
    isSuccess,
  } = useGetSimCardsQuery({
    page,
    per_page: perPage,
    order_by: "created_at",
    order_dir: "desc",
    q: JSON.stringify({ display_name_or_imsi_or_filter_ph_with_country_code_cont: simpleSearch }),
  });

  const {
    deleteItem: deleteSimCard,
    deleteErrorMsg,
    setDeleteErrorMsg,
  } = useDelete({
    deleteMutation: useDeleteSimCardMutation,
    closeModal: () => setShowDeleteWarning(false),
  });

  const headers = [
    {
      label: getLocalizedString("name", "Name"),
      key: "display_name",
      className: "name",
    },
    {
      label: getLocalizedString("type", "Type"),
      key: "sim_type",
      className: "sim-type",
    },
    {
      label: getLocalizedString("capabilities", "Capabilities"),
      type: "component",
      className: "capabilities more",
      component: ({ data }) => {
        const capabilities_names = data?.capabilities || [""];
        return <TableDropDownView data={capabilities_names} />;
      },
    },
    {
      label: getLocalizedString("imsi", "IMSI"),
      key: "imsi",
      className: "imsi",
    },
    {
      label: getLocalizedString("mobile_service_provider", "Mobile Service Provider"),
      key: "mobile_service_provider_name",
      className: "mobile-service-provider",
    },
    {
      label: getLocalizedString("phone_no", "Phone No."),
      nestedValue: true,
      getNestedValue: (element) =>
        `${element.dial_code ? `${element.dial_code}-` : ""}${element.phone_no || ""}`,
      className: "phone-number",
    },
    {
      label: getLocalizedString("domestic_roaming", "Domestic Roaming"),
      nestedValue: true,
      getNestedValue: (value) => <TickOrCross flag={value?.domestic_roaming_enabled} />,
      className: "domestic-roaming text-center",
    },
    {
      label: getLocalizedString("international_roaming", "International Roaming"),
      nestedValue: true,
      getNestedValue: (value) => <TickOrCross flag={value?.international_roaming_enabled} />,
      className: "international-roaming text-center",
    },
    {
      label: getLocalizedString("added_on", "Added On"),
      key: "created_at",
      className: "date",
    },
  ];

  const onEdit = (data) => {
    setShowForm(true);
    setIdToEdit(data.id);
  };

  const onDelete = (data) => {
    setShowDeleteWarning(true);
    setIdToDelete(data.id);
  };

  const onCreate = () => {
    setShowForm(true);
    setIdToEdit(null);
  };

  const getDeleteItemName = (id) => {
    let name = "";
    const index = data?.data.findIndex((item) => item.id === id);

    if (index >= 0) {
      name = data.data[index].display_name;
    }
    return name;
  };

  return (
    <>
      <article className="main-container">
        <MPSharedHeader
          groupName="sim_cards"
          resourceName="Sim Cards"
          createNewHandler={onCreate}
          heading={getLocalizedString("sim_cards", "Simcards")}
          handleSearchKey={setSimpleSearch}
          simpleSearchPlaceholder={getLocalizedString(
            "search_by_name_imsi_phone_no",
            "Search by Name, IMSI, Phone No."
          )}
        />

        <MPSharedTable
          resourceName="Sim Cards"
          data={data?.data || []}
          error={error}
          isLoading={isFetching}
          isSuccess={isSuccess}
          headers={headers}
          onEdit={onEdit}
          onDelete={onDelete}
          pagination={{ ...pagination, count: data.total_count }}
          skipClientId
          className="sim-cards no-tabs"
          auditResource="SimCard"
          auditKey="name"
        />
      </article>

      {showForm && (
        <SimCardsForm
          setUpdated={setUpdated}
          idToEdit={idToEdit}
          onClose={() => setShowForm(false)}
        />
      )}

      <MPSharedDeleteModal
        show={showDeleteWarning}
        error={deleteErrorMsg}
        entityName="SIM Card"
        itemName={getDeleteItemName(idToDelete)}
        onHide={() => {
          setDeleteErrorMsg("");
          setShowDeleteWarning(false);
        }}
        onDelete={() => deleteSimCard({ id: idToDelete })}
      />
    </>
  );
};
