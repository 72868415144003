import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Components from "../../../components";
import { FormContainer } from "../Shared";
import { useCreateDeviceGroupCsvMutation, useUpdateDeviceGroupCsvMutation } from "../services";
import { useForm } from "../../../hooks";
import { DeviceGroupCsvUploadFormSchema } from "../validations";
import { getCsvDetails } from "../../../shared/helper";
import { useGetDeviceGroupsQuery } from "../services";
import { getLocalizedString } from "../../../shared/translation";

export const DeviceGroupCsvUploadForm = ({ idToEditOrCsvUpload = null, onClose }) => {
  const dropdownParams = { per_page: 1000, order_by: "name" };
  const [initialValues, setInitialValues] = useState({
    csv_file: null,
    update_if_exist: false,
    group_id: "",
    csv_column_mappings: {
      manufacturer: "",
      model: "",
      variable_mapping_name: "",
      name: "",
      imei: "",
      primary_sim_card: "",
      firmware_version: "",
      enabled: "",
      geolocation_plan_name: "",
      trip_configuration_name: "",
      sms_username: "",
      sms_password: "",
      app_names: "",
      backend_names: "",
      backend_username: "",
      backend_password: "",
    },
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: DeviceGroupCsvUploadFormSchema,
    onSubmit: (value) => {
      handleFormSubmit(value);
    },
  });

  const [headers, setHeaders] = useState([]);
  const [selectedHeaders, setSelectedHeaders] = useState([]);
  const [isCsvUpload, setIsCsvUpload] = useState(false);

  useEffect(() => {
    let tempArray = [];
    Object.keys(formik.values.csv_column_mappings).map((key) => {
      if (formik.values.csv_column_mappings[key]) {
        tempArray.push(formik.values.csv_column_mappings[key]);
      }
    });
    setSelectedHeaders(tempArray);
  }, [formik.values.csv_column_mappings]);

  useEffect(() => {
    if (formik.values.csv_file) {
      getCsvDetails(formik.values.csv_file, updateCsvDetails);
    }
  }, [formik.values.csv_file]);

  //To set the device group when asset is imported for a particular device group
  useEffect(() => {
    if (idToEditOrCsvUpload) {
      formik.setFieldValue("group_id", idToEditOrCsvUpload);
    }
  }, [idToEditOrCsvUpload]);

  const { create: createDeviceGroupCsv } = useForm({
    createMutation: useCreateDeviceGroupCsvMutation,
    updateMutation: useUpdateDeviceGroupCsvMutation,
    closeForm: onClose,
    setError: formik.setErrors,
  });

  const { data: deviceGroups, isSuccess: isDeviceGroupsSuccess } =
    useGetDeviceGroupsQuery(dropdownParams);

  const handleFormSubmit = (formData) => {
    if (idToEditOrCsvUpload === null) {
      idToEditOrCsvUpload = formik.values.group_id;
    }
    const formObj = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key === "csv_column_mappings") {
        formObj.append(key, JSON.stringify(formData[key]));
      } else {
        if (formData[key] !== null) {
          formObj.append(key, formData[key]);
        }
      }
    });
    createDeviceGroupCsv({ id: idToEditOrCsvUpload, formData: formObj });
  };

  const updateCsvDetails = (headers, csvSize) => {
    setHeaders(headers);
    setIsCsvUpload(true);
  };

  const showWarningMessage = (value) => {
    let countValueOccurence = 0;
    selectedHeaders.map((header) => {
      if (header === value) {
        countValueOccurence++;
      }
    });
    if (countValueOccurence > 1) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <FormContainer
      closeForm={onClose}
      handleFormSubmit={formik.handleSubmit}
      customResourceName="Import Devices"
    >
      <Components.QICustomSelect
        label={getLocalizedString("group", "Group")}
        {...formik.getFieldProps("group_id")}
        onChange={(value) => {
          formik.setFieldValue("group_id", value);
        }}
        error={formik?.touched?.group_id && formik?.errors?.group_id}
        disabled={idToEditOrCsvUpload ? true : false}
        labelClassName="group"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {deviceGroups?.data?.map((group, index) => (
            <li value={group?.id} key={index}>
              {group?.name}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QIFileUploader
        label={getLocalizedString("import_devices", "Import Devices")}
        placeholder={getLocalizedString("only_csv_file", "Only .csv files")}
        value={formik.values.csv_file}
        onChange={(files) => formik.setFieldValue("csv_file", files[0])}
        error={formik.touched.csv_file && formik.errors.csv_file}
      />

      <Components.QISwitch
        label={getLocalizedString("update_if_exists", "Update if exists")}
        {...formik.getFieldProps("update_if_exist")}
        error={formik.touched.update_if_exist && formik.errors.update_if_exist}
        onChange={() => formik.setFieldValue("update_if_exist", !formik.values.update_if_exist)}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        readOnly={!formik.values.csv_file || formik.errors.csv_file}
      />

      <Components.QICustomSelect
        label={getLocalizedString("manufacturer", "Manufacturer")}
        {...formik.getFieldProps("csv_column_mappings.manufacturer")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.manufacturer", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.manufacturer &&
          formik?.errors?.csv_column_mappings?.manufacturer
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.manufacturer)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="manufacturer"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("model", "Model")}
        {...formik.getFieldProps("csv_column_mappings.model")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.model", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.model && formik?.errors?.csv_column_mappings?.model
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.model)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="model"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("variable_mapping", "Variable Mapping")}
        {...formik.getFieldProps("csv_column_mappings.variable_mapping_name")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.variable_mapping_name", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.variable_mapping_name &&
          formik?.errors?.csv_column_mappings?.variable_mapping_name
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.variable_mapping_name)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="variable-mapping"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("trip_configuration", "Trip Configuration")}
        {...formik.getFieldProps("csv_column_mappings.trip_configuration_name")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.trip_configuration_name", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.trip_configuration_name &&
          formik?.errors?.csv_column_mappings?.trip_configuration_name
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.trip_configuration_name)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="trip-configuration"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("name", "Name")}
        {...formik.getFieldProps("csv_column_mappings.name")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.name", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.name && formik?.errors?.csv_column_mappings?.name
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.name)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="name"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label="ID/IMEI"
        {...formik.getFieldProps("csv_column_mappings.imei")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.imei", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.imei && formik?.errors?.csv_column_mappings?.imei
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.imei)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="id-imei"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("primary_sim_card", "Primary SIM Card")}
        {...formik.getFieldProps("csv_column_mappings.primary_sim_card")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.primary_sim_card", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.primary_sim_card &&
          formik?.errors?.csv_column_mappings?.primary_sim_card
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.primary_sim_card)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="simcard-no"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("sms_username", "SMS Username")}
        {...formik.getFieldProps("csv_column_mappings.sms_username")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.sms_username", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.sms_username &&
          formik?.errors?.csv_column_mappings?.sms_username
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.sms_username)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="sms-username"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("sms_password", "SMS Password")}
        {...formik.getFieldProps("csv_column_mappings.sms_password")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.sms_password", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.sms_password &&
          formik?.errors?.csv_column_mappings?.sms_password
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.sms_password)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="sms-password"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("enabled", "Enabled")}
        {...formik.getFieldProps("csv_column_mappings.enabled")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.enabled", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.enabled &&
          formik?.errors?.csv_column_mappings?.enabled
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.enabled)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="enabled"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("applications", "Applications")}
        {...formik.getFieldProps("csv_column_mappings.app_names")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.app_names", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.app_names &&
          formik?.errors?.csv_column_mappings?.app_names
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.app_names)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="applications"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("backends", "Backends")}
        {...formik.getFieldProps("csv_column_mappings.backend_names")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.backend_names", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.backend_names &&
          formik?.errors?.csv_column_mappings?.backend_names
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.backend_names)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="backends"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("backend_username", "Backend Username")}
        {...formik.getFieldProps("csv_column_mappings.backend_username")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.backend_username", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.backend_username &&
          formik?.errors?.csv_column_mappings?.backend_username
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.backend_username)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="backend-username"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("backend_password", "Backend Password")}
        {...formik.getFieldProps("csv_column_mappings.backend_password")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.backend_password", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.backend_password &&
          formik?.errors?.csv_column_mappings?.backend_password
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.backend_password)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="backend-password"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("geolocation_plan", "Geolocation Plan")}
        {...formik.getFieldProps("csv_column_mappings.geolocation_plan_name")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.geolocation_plan_name", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.geolocation_plan_name &&
          formik?.errors?.csv_column_mappings?.geolocation_plan_name
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.geolocation_plan_name)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="geolocation-plan"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("firmware_version", "Firmware Version")}
        {...formik.getFieldProps("csv_column_mappings.firmware_version")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.firmware_version", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.firmware_version &&
          formik?.errors?.csv_column_mappings?.firmware_version
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.firmware_version)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="firmware-version"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>
    </FormContainer>
  );
};
