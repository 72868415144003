import React, { useState } from "react";
import sprite from "../../assets/icons.svg";
import { QISimpleSearch } from "../QISimpleSearch";

export const QIExpand = ({
  title,
  children,
  setDockAlign,
  mapExpand,
  setMapExpand,
  simpleSearch,
  setSimpleSearch,
  setDetails,
  setDeviceId = () => {},
  simpleSearchPlaceholder,
  alternativeUI,
  subNavigation = <></>,
}) => {
  const [expand, setExpand] = useState(false);
  const handleExpand = () => {
    setExpand((prevState) => !prevState);
    setMapExpand(!expand);
  };

  return (
    <>
      {expand ? (
        <div className="qi-collapsed" onClick={handleExpand}>
          {alternativeUI && (
            <span className="qi-collapsed_icon">
              <svg className="icon rotate-180">
                <use href={`${sprite}#collapse`}></use>
              </svg>
            </span>
          )}
          {!alternativeUI && (
            <span className="qi-collapsed_icon">
              <svg className="icon">
                <use href={`${sprite}#collapse`}></use>
              </svg>
            </span>
          )}

          <span className="qi-collapsed_text">{title}</span>
        </div>
      ) : (
        <aside className="qi-expanded map-view-flyout">
          <header className="qi-expanded_header">
            {!alternativeUI && (
              <span onClick={handleExpand} className="collapse-icon-wrapper">
                <svg className="icon rotate-180">
                  <use href={`${sprite}#collapse`}></use>
                </svg>
              </span>
            )}
            <strong className="text-lg pl-3">{title}</strong>
            <QISimpleSearch
              searchKey={simpleSearch}
              handleSearchChange={setSimpleSearch}
              simpleSearchPlaceholder={simpleSearchPlaceholder}
              setDetails={setDetails}
              setDockAlign={setDockAlign}
              setDeviceId={setDeviceId}
            />
            {alternativeUI && (
              <span onClick={handleExpand} className="collapse-icon-wrapper">
                <svg className="icon">
                  <use href={`${sprite}#collapse`}></use>
                </svg>
              </span>
            )}
          </header>

          {subNavigation}
          <div className="qi-expanded_body asset-list-wrapper">{children}</div>
        </aside>
      )}
    </>
  );
};
