import { useState } from "react";
import { useDelete, usePagination, useSearchQuery } from "../../../hooks";
import { MPSharedHeader, MPSharedTable, MPSharedDeleteModal } from "../Shared";
import { getLocalizedString } from "../../../shared/translation";
import { ReportsForm } from "./form";
import {
  useGetResellerMonthlyReportsQuery,
  useDeleteResellerMonthlyReportMutation,
} from "../services";
import { capitalize, capitalizeAllFirstLetters } from "../../Tracking/utils";
import { utcToLocalGlobal } from "../../../shared/utility";
import sprite from "../../../assets/icons.svg";
import { SvgIcon } from "../../Shared";

export const ReportsContainer = () => {
  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_cont",
  });

  const [idToEdit, setIdToEdit] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [setIsNew] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetResellerMonthlyReportsQuery({
    page,
    per_page: perPage,
    order_by: "created_at",
    order_dir: "desc",
    q,
    //filter_user_id: getUserId(),
  });
  const {
    deleteItem: deleteReport,
    deleteErrorMsg,
    setDeleteErrorMsg,
  } = useDelete({
    deleteMutation: useDeleteResellerMonthlyReportMutation,
    closeModal: () => setShowDeleteWarning(false),
  });

  const headers = [
    {
      label: getLocalizedString("name", "Name"),
      key: "name",
      className: "name",
    },
    {
      label: getLocalizedString("report_type", "Report Type"),
      id: 2,
      className: "report-type",
      type: "component",
      component: ({ data }) => {
        return <span> {capitalize(data?.report_type)} </span>;
      },
    },
    {
      label: getLocalizedString("subtype", "Sub-Type"),
      className: "report-sub-type",
      type: "component",
      component: ({ data }) => {
        return <span> {capitalize(data?.report_sub_type)} </span>;
      },
    },
    {
      label: getLocalizedString("summary_interval", "Sumary Interval"),
      className: "report-sub-type",
      type: "component",
      component: ({ data }) => {
        return <span> {capitalize(data?.summary_interval)} </span>;
      },
    },
    {
      label: getLocalizedString("status", "Status"),
      id: 7,
      nestedValue: true,
      getNestedValue: ({ status }) => {
        switch (status) {
          case "in_progress":
            status = "In Progress";
            break;
          case "pending":
            status = "Scheduled";
            break;
          case "failure":
            status = "Failed";
            break;
          default:
            break;
        }
        status = status.replace("_", " ");
        return <h1 title={status}>{capitalize(status)}</h1>;
      },
    },
    {
      label: getLocalizedString("created_by", "Created By"),
      id: 8,
      key: "created_by",
      className: "added-by",
    },
    {
      label: getLocalizedString("created_at", "Created At"),
      id: 9,
      nestedValue: true,
      getNestedValue: ({ created_at }) => {
        return <h1 title={utcToLocalGlobal(created_at)}>{utcToLocalGlobal(created_at)}</h1>;
      },
    },
    {
      label: getLocalizedString("download", "Download"),
      id: 10,
      className: "text-center",
      nestedValue: true,
      getNestedValue: ({ report_file, status }) => {
        let statusIcon;
        switch (status) {
          case "in_progress":
            statusIcon = "time";
            break;
          case "pending":
            statusIcon = "time";
            break;
          case "failure":
            statusIcon = "cross";
            break;
          case "completed":
            statusIcon = "tick";
            break;
          default:
            break;
        }
        return (
          <>
            {report_file ? (
              <a href={report_file} className="download-status">
                <SvgIcon name="download" wrapperClass="icon-download" svgClass="icon" />
              </a>
            ) : (
              <span
                className="download-status status-in-progress disabled-button"
                title={capitalizeAllFirstLetters(status)}
              >
                <svg className="icon">
                  <use href={`${sprite}#${statusIcon}`}></use>
                </svg>
              </span>
            )}
          </>
        );
      },
    },
  ];

  const createNewHandler = () => {
    setShowForm(true);
  };

  const deleteClickHandler = (datum) => {
    setShowDeleteWarning(true);
    setIdToDelete(datum.id);
  };

  const getDeleteItemName = (id) => {
    let name = "";
    const index = data?.data?.findIndex((datum) => datum.id === id);

    if (index > -1) {
      name = data?.data[index].name;
    }
    return name;
  };

  return (
    <>
      <article className="main-container">
        <MPSharedHeader
          resourceName="Report"
          createNewHandler={createNewHandler}
          groupName="Reports"
          addNewLabel={getLocalizedString("generate_report", "Generate Report")}
          heading={getLocalizedString("reports", "Reports")}
          handleSearchKey={setSimpleSearch}
          simpleSearchPlaceholder={getLocalizedString("search_by_name", "Search by Name")}
          skipCanCreate={true}
        />
        <MPSharedTable
          resourceName="Report Configuration"
          isLoading={isFetching}
          error={error}
          isSuccess={isSuccess}
          headers={headers}
          data={data.data}
          pagination={{ ...pagination, count: data.total_count }}
          onDelete={deleteClickHandler}
          className="report"
          auditKey=""
          skipCanDelete={true}
          skipClientId
        />
      </article>

      {showForm && <ReportsForm idToEdit={idToEdit} onClose={() => setShowForm(false)} />}

      <MPSharedDeleteModal
        show={showDeleteWarning}
        entityName={getLocalizedString("report", "Report")}
        error={deleteErrorMsg}
        itemName={getDeleteItemName(idToDelete)}
        onHide={() => {
          setDeleteErrorMsg();
          setShowDeleteWarning(false);
        }}
        onDelete={() => deleteReport({ id: idToDelete })}
      />
    </>
  );
};
