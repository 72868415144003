import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCollectionQuery, baseQueryAuth } from "../../reduxStore/helper";

export const trackingApi = createApi({
  name: "trackingApi",
  reducerPath: "trackingApi",
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_TRACKING_API + "/formatted/latest",
    prepareHeaders: (headers, { getState }) => {
      const authToken = sessionStorage.getItem("fmdp-access-token");
      if (authToken) {
        headers.set("Authorization", authToken);
      }
      return headers;
    },
  }),
  tagTypes: ["devices"],
  endpoints: (builder) => ({
    getDevicesTracking: builder.query({
      query: (queryParams) => {
        let queryParam = [];
        if (queryParams?.filter && queryParams?.q !== "") {
          queryParam = JSON.stringify({
            $or: [
              {
                "device_data.other_data.device_name.v": {
                  $like: queryParams.q || "",
                },
              },
              {
                "heartbeat.other_data.device_name.v": {
                  $like: queryParams.q || "",
                },
              },
              {
                "device_reply.other_data.device_name.v": {
                  $like: queryParams.q || "",
                },
              },
              {
                "login.other_data.device_name.v": {
                  $like: queryParams.q || "",
                },
              },
              {
                "disconnect.other_data.device_name.v": {
                  $like: queryParams.q || "",
                },
              },
              {
                "registration.other_data.device_name.v": {
                  $like: queryParams.q || "",
                },
              },
              {
                "device_data.source_id": {
                  $like: queryParams.q || "",
                },
              },
              {
                "heartbeat.source_id": {
                  $like: queryParams.q || "",
                },
              },
              {
                "registration.source_id": {
                  $like: queryParams.q || "",
                },
              },
              {
                "disconnect.source_id": {
                  $like: queryParams.q || "",
                },
              },
              {
                "login.source_id": {
                  $like: queryParams.q || "",
                },
              },
              {
                "device_reply.source_id": {
                  $like: queryParams.q || "",
                },
              },
            ],
          });
        }
        return {
          url: `devices?${
            queryParams.device_ids ? `device_ids=${queryParams.device_ids}&` : ""
          }page=${queryParams.page}&per_page=${queryParams.per_page}`,
          method: "GET",
          params: {
            q: queryParam,
          },
        };
      },
    }),
  }),
});

export const trackingVehicleApi = createApi({
  name: "trackingVehicleApi",
  reducerPath: "trackingVehicleApi",
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_TRACKING_API + "/formatted/latest",
    prepareHeaders: (headers, { getState }) => {
      const authToken = sessionStorage.getItem("fmdp-access-token");
      if (authToken) {
        headers.set("Authorization", authToken);
      }
      return headers;
    },
  }),
  // tagTypes: ["vehicles"],
  endpoints: (builder) => ({
    getVehiclesTracking: builder.query({
      query: (queryParams) => {
        let queryParam = [];
        if (queryParams?.filter) {
          queryParam = JSON.stringify({
            "plate_number.v": {
              $like: queryParams.q || "",
            },
          });
        }
        return {
          url: `vehicles?${
            queryParams.plate_numbers ? `plate_numbers=${queryParams.plate_numbers}&` : ""
          }page=${queryParams.page}&per_page=${queryParams.per_page}`,
          method: "GET",
          params: {
            q: queryParam,
          },
        };
      },
    }),
  }),
});

export const reverseGeoCodingApi = createApi({
  name: "reverseGeoCodingApi",
  reducerPath: "reverseGeoCodingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_REVERSE_GEO_CODING_API,
    prepareHeaders: (headers, { getState }) => {
      const authToken = sessionStorage.getItem("fmdp-access-token");
      if (authToken) {
        headers.set("Authorization", authToken);
      }
      return headers;
    },
  }),
  tagTypes: ["reverse"],
  endpoints: (builder) => ({
    getAddress: getCollectionQuery(builder, "reverse"),
  }),
});

export const vehicleRouteApi = createApi({
  name: "vehicleRouteApi",
  reducerPath: "vehicleRouteApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_TRACKING_API + "/formatted/devices/",
    prepareHeaders: (headers, { getState }) => {
      const authToken = sessionStorage.getItem("fmdp-access-token");
      if (authToken) {
        headers.set("Authorization", authToken);
      }
      return headers;
    },
  }),
  tagTypes: ["route"],
  endpoints: (builder) => ({
    getVehicleRouting: builder.query({
      query: (queryParams) => ({
        url: `${queryParams.deviceId}?packet_types=device_data${
          queryParams.isTime
            ? `&start_time=${queryParams.start_time}&end_time=${queryParams.end_time}`
            : ""
        }&per_page=${queryParams.per_page}&page=${queryParams.page}`,
        method: "get",
      }),
      keepUnusedDataFor: 2,
      providesTags: ["route"],
    }),
  }),
});

//Trips API
export const tripsApi = createApi({
  name: "tripsApi",
  reducerPath: "tripsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_TRACKING_API + "/trips",
    prepareHeaders: (headers, { getState }) => {
      const authToken = sessionStorage.getItem("fmdp-access-token");
      if (authToken) {
        headers.set("Authorization", authToken);
      }
      return headers;
    },
  }),
  tagTypes: ["trips"],
  endpoints: (builder) => ({
    getTrips: builder.query({
      query: (queryParams) => {
        let search = queryParams?.search;
        const type = queryParams?.type;
        // If search is false then search params are not sent
        let qParam = queryParams?.isAdmin
          ? search &&
            encodeURIComponent(
              JSON.stringify({
                $or: [
                  {
                    plate_number: {
                      $like: queryParams.q,
                    },
                    status: {
                      $like: queryParams.q,
                    },
                  },
                ],
              })
            )
          : search
          ? encodeURIComponent(
              JSON.stringify({
                $and: [
                  {
                    plate_number: {
                      $in: queryParams.plate_number,
                    },
                  },
                ],
              })
            )
          : type === "Vehicles"
          ? encodeURIComponent(
              JSON.stringify({
                $and: [
                  {
                    plate_number: {
                      $in: queryParams.plate_number,
                    },
                  },
                ],
              })
            )
          : encodeURIComponent(
              JSON.stringify({
                $and: [
                  {
                    source_id: {
                      $in: queryParams.source_id,
                    },
                  },
                ],
              })
            );
        return {
          url: `?start_time=${queryParams.startTime}&end_time=${queryParams.endTime}&per_page=${
            queryParams.per_page
          }&page=${queryParams.page}&client_id=${queryParams.clientId}&q=${
            queryParams.q || queryParams.plate_number || queryParams.source_id ? qParam : ""
          }`,
          method: "get",
        };
      },
      keepUnusedDataFor: 2,
      providesTags: ["trips"],
    }),
  }),
});

//Activities API
export const tripsTrackApi = createApi({
  name: "tripsTrackApi",
  reducerPath: "tripsTrackApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_TRACKING_API + "/activities",
    prepareHeaders: (headers, { getState }) => {
      const authToken = sessionStorage.getItem("fmdp-access-token");
      if (authToken) {
        headers.set("Authorization", authToken);
      }
      return headers;
    },
  }),
  tagTypes: ["tripsTrack"],
  endpoints: (builder) => ({
    getTripsTrack: builder.query({
      query: (queryParams) => {
        const search = queryParams?.search;
        const type = queryParams?.type;
        // If search is false then search params are not sent
        let qParam = queryParams?.isAdmin
          ? search &&
            encodeURIComponent(
              JSON.stringify({
                $or: [
                  {
                    plate_number: {
                      $like: queryParams.q,
                    },
                  },
                  {
                    modes: {
                      $like: queryParams.q,
                    },
                  },
                  {
                    source_id: {
                      $like: queryParams.q,
                    },
                  },
                ],
              })
            )
          : search
          ? encodeURIComponent(
              JSON.stringify({
                $and: [
                  {
                    plate_number: {
                      $in: queryParams.plate_number,
                    },
                  },
                ],
              })
            )
          : type === "Vehicles"
          ? encodeURIComponent(
              JSON.stringify({
                $and: [
                  {
                    plate_number: {
                      $in: queryParams.plate_number,
                    },
                  },
                ],
              })
            )
          : encodeURIComponent(
              JSON.stringify({
                $and: [
                  {
                    source_id: {
                      $in: queryParams.source_id,
                    },
                  },
                ],
              })
            );
        function vehicleStatus() {
          if (queryParams.q === "TRIP") {
            return "TRIP";
          } else {
            if (queryParams.q === "PARKED") {
              return "PARKED";
            } else {
              return "TRIP%2CPARKED";
            }
          }
        }
        return {
          url: `?start_time=${queryParams.startTime}&end_time=${queryParams.endTime}&per_page=${
            queryParams.per_page
          }&page=${queryParams.page}&client_id=${queryParams.clientId}&modes=${vehicleStatus()}&q=${
            queryParams.q || queryParams.plate_number || queryParams.source_id ? qParam : ""
          }`,
          method: "get",
        };
      },
      keepUnusedDataFor: 2,
      providesTags: ["tripsTrack"],
    }),
  }),
});

//Events API
export const eventsApi = createApi({
  name: "eventsApi",
  reducerPath: "eventsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_TRACKING_API + "/events",
    prepareHeaders: (headers, { getState }) => {
      const authToken = sessionStorage.getItem("fmdp-access-token");
      if (authToken) {
        headers.set("Authorization", authToken);
      }
      return headers;
    },
  }),
  tagTypes: ["events"],
  endpoints: (builder) => ({
    getEvents: builder.query({
      query: (queryParams) => {
        const search = queryParams?.search;
        const type = queryParams?.type;
        // If search is false then search params are not sent
        let qParam = queryParams?.isAdmin
          ? search &&
            encodeURIComponent(
              JSON.stringify({
                $or: [
                  {
                    plate_number: {
                      $like: queryParams.q,
                    },
                    "events.type": {
                      $like: queryParams.q,
                    },
                    "events.sub_type": {
                      $like: queryParams.q,
                    },
                    "events.name": {
                      $like: queryParams.q,
                    },
                    "events.config.name": {
                      $like: queryParams.q,
                    },
                    "events.status": {
                      $like: queryParams.q,
                    },
                  },
                ],
              })
            )
          : search
          ? encodeURIComponent(
              JSON.stringify({
                $and: [
                  {
                    plate_number: {
                      $in: queryParams.plate_number,
                    },
                  },
                ],
                $or: [
                  {
                    plate_number: {
                      $like: queryParams.q,
                    },
                    "events.type": {
                      $like: queryParams.q,
                    },
                    "events.sub_type": {
                      $like: queryParams.q,
                    },
                    "events.name": {
                      $like: queryParams.q,
                    },
                    "events.config.name": {
                      $like: queryParams.q,
                    },
                    "events.status": {
                      $like: queryParams.q,
                    },
                  },
                ],
              })
            )
          : type === "Vehicles"
          ? encodeURIComponent(
              JSON.stringify({
                $and: [
                  {
                    plate_number: {
                      $in: queryParams.plate_number,
                    },
                  },
                ],
              })
            )
          : encodeURIComponent(
              JSON.stringify({
                $and: [
                  {
                    source_id: {
                      $in: queryParams.source_id,
                    },
                  },
                ],
              })
            );
        return {
          url: `?start_time=${queryParams.startTime}&end_time=${queryParams.endTime}&per_page=${
            queryParams.perPage
          }&page=${queryParams.page}&client_id=${queryParams.clientId}&q=${
            queryParams.q || queryParams.plate_number || queryParams.source_id ? qParam : ""
          }`,
          method: "get",
        };
      },
      keepUnusedDataFor: 2,
      providesTags: ["events"],
    }),
  }),
});

//Raw Data API
export const rawDataApi = createApi({
  name: "rawDataApi",
  reducerPath: "rawDataApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_TRACKING_API + "/raw/devices",
    prepareHeaders: (headers, { getState }) => {
      const authToken = sessionStorage.getItem("fmdp-access-token");
      if (authToken) {
        headers.set("Authorization", authToken);
      }
      return headers;
    },
  }),
  tagTypes: ["rawData"],
  endpoints: (builder) => ({
    getRawData: builder.query({
      query: (queryParams) => {
        return {
          url: `${queryParams?.deviceId}?end_time=${queryParams?.end_time}&start_time=${queryParams?.start_time}&per_page=${queryParams.perPage}&page=${queryParams.page}&decode=${queryParams?.decode}&sort=${queryParams?.sort}`,
          method: "get",
        };
      },
      keepUnusedDataFor: 2,
      providesTags: ["rawData"],
    }),
  }),
});

//Variables API
export const variablesApi = createApi({
  name: "variablesApi",
  reducerPath: "variablesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_CORE_API_URL + "/variables",
    prepareHeaders: (headers, { getState }) => {
      const authToken = sessionStorage.getItem("fmdp-access-token");
      if (authToken) {
        headers.set("Authorization", authToken);
      }
      return headers;
    },
  }),
  tagTypes: ["variables"],
  endpoints: (builder) => ({
    getVariablesData: builder.query({
      query: (queryParams) => {
        return {
          url: `?per_page=${queryParams.per_page}&page=${queryParams.page}`,
          method: "get",
        };
      },
      keepUnusedDataFor: 2,
      providesTags: ["variables"],
    }),
  }),
});

export const { useGetDevicesTrackingQuery, useGetDeviceDetailsQuery, useGetTrackingDataQuery } =
  trackingApi;
export const { useGetVehiclesTrackingQuery } = trackingVehicleApi;
export const { useGetAddressQuery } = reverseGeoCodingApi;
export const { useGetVehicleRoutingQuery } = vehicleRouteApi;
export const { useGetTripsQuery } = tripsApi;
//Activities API
export const { useGetTripsTrackQuery } = tripsTrackApi;
export const { useGetEventsQuery } = eventsApi;
export const { useGetRawDataQuery } = rawDataApi;
export const { useGetVariablesDataQuery } = variablesApi;
