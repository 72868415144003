import React, { useState, useEffect } from "react";
import { VehicleForm } from "./form";
import { VehicleAdvanceSearch } from "./advanceSearch";
import { useSearchQuery, usePagination } from "../../../hooks";
import { QIDropdown } from "../../../components";
import { useDelete, useQuery } from "../../../hooks";
import { MPSharedTable, MPSharedDeleteModal, MPSharedHeader, TableDropDownView } from "../Shared/";
import { useGetVehiclesQuery, useDeleteVehicleMutation } from "../services";
import { VehicleGroupCsvUploadForm } from "../VehicleGroups/csvUploadForm";
import { getLocalizedString } from "../../../shared/translation";
import { vehiclePlaceholder } from "../../../assets";

export const VehiclesContainer = () => {
  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, advanceSearch, setAdvanceSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_or_plate_number_cont",
    additionalSimpleSearchKeys: ["filter_device_imei_cont"],
  });

  const queryParams = {
    page: page,
    per_page: perPage,
    order_by: "vehicles.created_at",
    order_dir: "desc",
  };

  const query = useQuery();
  let filterHeader = "";
  const filters = [
    {
      label: getLocalizedString("vehicle_group_lowercase", " vehicle group") + ":",
      key: "vehicle_group_id",
    },
  ];

  filters.map((filter) => {
    if (query.get(filter.key)) {
      queryParams[filter.key] = query.get(filter.key);
      filterHeader = `${filter.label} ${query.get("name")}`;
    }
  });

  const {
    data = { data: [], total_count: 0 },
    error,
    isFetching,
    isSuccess,
  } = useGetVehiclesQuery({ ...queryParams, q });

  const {
    deleteItem: deleteVehicle,
    deleteErrorMsg,
    setDeleteErrorMsg,
  } = useDelete({
    deleteMutation: useDeleteVehicleMutation,
    closeModal: () => setShowDeleteWarning(false),
  });

  const [idToDelete, setIdToDelete] = useState(null);
  const [idToEdit, setIdToEdit] = useState(null);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [showForm, setShowForm] = useState(false);

  //For the csv upload form for vehicles asset creation
  const [showCsvUploadForm, setShowCsvUploadForm] = useState(false);

  const onEdit = (data) => {
    setShowForm(true);
    setIdToEdit(data.id);
  };

  const onDelete = (data) => {
    setShowDeleteWarning(true);
    setIdToDelete(data.id);
  };

  const onCreate = () => {
    setShowForm(true);
    setIdToEdit(null);
  };

  // const vehicleModelInfo = ({
  //   vehicle_manufacturer_name,
  //   vehicle_manufacturer_model_name,
  //   vehicle_manufacturer_model_variant_name,
  //   capacity,
  //   engine_capacity,
  //   year,
  // }) => {
  //   return `
  //     Model: ${vehicle_manufacturer_model_name},
  //     Engine Capacity: ${engine_capacity},
  //     Capacity:${capacity || "0"} seater ,
  //     Year: ${year || ""},
  //     Manufacturer: ${vehicle_manufacturer_name || "N/A"},
  //     Variant: ${vehicle_manufacturer_model_variant_name || "N/A"}
  //   `;
  // };

  const headers = [
    {
      key: "thumbnail",
      nestedValue: true,
      getNestedValue: ({ thumbnail }) => {
        if (!thumbnail || thumbnail === undefined) {
          return <img className="vehicle-image" src={vehiclePlaceholder} />;
        } else {
          return <img className="vehicle-image" alt="image" title="" src={thumbnail} />;
        }
      },
      className: "photo",
    },
    {
      label: getLocalizedString("name", "Name"),
      key: "name",
      className: "name",
    },
    {
      label: getLocalizedString("plate_no", "Plate No."),
      key: "plate_number",
      className: "plate-number",
    },
    {
      label: getLocalizedString("engine_number", "Engine Number"),
      key: "engine_number",
      className: "engine-number",
    },
    {
      label: getLocalizedString("chasis_number", "Chassis Number"),
      key: "chasis_number",
      className: "chasis-number",
    },
    {
      label: getLocalizedString("specification", "Specification"),
      key: "vehicle_model_name",
      className: "model",
    },
    {
      label: getLocalizedString("groups", "Groups"),
      type: "component",
      className: "group-name more",
      component: ({ data }) => {
        const group_names = data?.vehicle_group_names || [""];
        return <TableDropDownView data={group_names} />;
      },
    },
    {
      label: getLocalizedString("devices", "Devices"),
      type: "component",
      className: "devices more",
      component: ({ data }) => {
        const deviceName = data?.device_name_list?.split(",") || [""];
        return (
          <>
            <span className="associated-devices_initial" title={deviceName[0]}>
              {deviceName[0]}
            </span>

            <QIDropdown
              toggleComponent={
                <div>
                  {deviceName?.length > 1 && (
                    <span className="associated-devices_more rounded">
                      + {deviceName.length - 1} more
                    </span>
                  )}
                </div>
              }
            >
              <ul className="associated-devices_list">
                {deviceName.map((deviceName, index) => (
                  <li className="associated-devices_list_item" title={deviceName} key={index}>
                    {deviceName}
                  </li>
                ))}
              </ul>
            </QIDropdown>
          </>
        );
      },
    },
    {
      label: getLocalizedString("user", "User"),
      key: "user_name",
      className: "w-25",
    },
    {
      label: getLocalizedString("added_on", "Added On"),
      key: "created_at",
      className: "date",
    },
  ];

  const getDeleteItemName = (id) => {
    let name = "";
    const index = data?.data.findIndex((item) => item.id === id);

    if (index >= 0) {
      name = data?.data[index]?.name + " (" + data?.data[index]?.plate_number + ")";
    }
    return name;
  };

  //Handler to set the csv upload form open
  const uploadCsvHandler = () => {
    setShowCsvUploadForm(true);
  };

  return (
    <>
      <article className="main-container">
        <MPSharedHeader
          groupName="vehicle"
          createNewHandler={onCreate}
          resourceName="Vehicles"
          heading={getLocalizedString("vehicles", "Vehicles")}
          handleSearchKey={setSimpleSearch}
          handleAdvanceSearchKeys={(value) => setAdvanceSearch(null)}
          simpleSearchPlaceholder={getLocalizedString(
            "search_by_name_plate_no_device_imei",
            "Search by Name, Plate No., Device IMEI"
          )}
          filterText={
            filterHeader && `${getLocalizedString("filtered_by", "Filtered by")} ${filterHeader}`
          }
          uploadCsvHandler={uploadCsvHandler}
        >
          <VehicleAdvanceSearch
            onSearch={(value) => setAdvanceSearch(value)}
            activeSearch={advanceSearch}
          />
        </MPSharedHeader>

        <MPSharedTable
          resourceName="Vehicles"
          data={data?.data || []}
          error={error}
          isLoading={isFetching}
          isSuccess={isSuccess}
          headers={headers}
          onEdit={onEdit}
          onDelete={onDelete}
          pagination={{ ...pagination, count: data.total_count }}
          skipClientId
          className="vehicles"
          auditResource="Vehicle"
          auditKey="plate_number"
        />
      </article>

      {showCsvUploadForm && (
        <VehicleGroupCsvUploadForm onClose={() => setShowCsvUploadForm(false)} />
      )}

      {showForm && <VehicleForm idToEdit={idToEdit} onClose={() => setShowForm(false)} />}

      <MPSharedDeleteModal
        show={showDeleteWarning}
        entityName={getLocalizedString("vehicle", "vehicle")}
        error={deleteErrorMsg}
        itemName={getDeleteItemName(idToDelete)}
        onHide={() => {
          setDeleteErrorMsg("");
          setShowDeleteWarning(false);
        }}
        onDelete={() => deleteVehicle({ id: idToDelete })}
      />
    </>
  );
};
