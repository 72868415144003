import TripList from "../../Trips/TripsList";
import { useGetTripsQuery } from "../../services";
import { useEffect, useState } from "react";
import { QIErrorBoundary } from "../../../../components";
import moment from "moment";
import "./style.scss";
import { header } from "../../Trips/tableHeader";
import { getLocalizedString } from "../../../../shared/translation";

export const TripsContainer = ({
  deviceId,
  clientId,
  selectedRange,
  resetCount,
  setResetCount,
  setSelectedRange,
  selectedVehiclePlate,
  type,
  setActivitiesTimeRange = () => {},
  setListCount = () => {},
}) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [tripSearch, setTripSearch] = useState("");
  const [selectedId, setSelectedId] = useState();

  //Pagination
  const pagination = { page, perPage, onPageChange: setPage, onPerPageChange: setPerPage };

  const message = {
    header: getLocalizedString("no_device_selected", ""),
    content: getLocalizedString("please_select_a_device", "Please Select a Device"),
  };

  //Get Trips data
  const {
    data: tripsData,
    error,
    isFetching,
    isSuccess,
  } = useGetTripsQuery(
    deviceId && {
      startTime: moment(selectedRange.startDate).valueOf(),
      endTime: moment(selectedRange.endDate).valueOf(),
      deviceId,
      clientId: clientId,
      plate_number: [selectedVehiclePlate],
      source_id: [deviceId],
      type: type,
      search: false,
      page: page - 1,
      per_page: perPage,
      q: "",
    }
  );

  // Get end time if present for past trip or return current time for in progress trip
  const getEndTime = (endTime) => {
    if (endTime !== null) {
      return moment(endTime);
    } else {
      return moment();
    }
  };

  useEffect(() => {
    if (tripsData?.trips?.length > 0) {
      setActivitiesTimeRange({
        start_time: moment(tripsData?.trips[0]?.start_time),
        end_time: getEndTime(tripsData?.trips[0]?.end_time),
      });
      setSelectedId(0);
    }
  }, [tripsData?.trips]);

  //To set the total count of data in the list
  useEffect(() => {
    setListCount(tripsData?.total_count);
  }, [tripsData?.total_count]);

  return deviceId ? (
    <section className="vehicle-trips">
      <TripList
        isFetching={isFetching}
        isSuccess={isSuccess}
        data={tripsData || []}
        header={header}
        resetCount={resetCount}
        setResetCount={setResetCount}
        selectedRange={selectedRange}
        setSelectedRange={setSelectedRange}
        error={error}
        listType={"Trips"}
        selectedPage={"Trips"}
        page={page}
        perPage={perPage}
        setPage={setPage}
        setPerPage={setPerPage}
        simpleSearch={tripSearch}
        setSimpleSearch={setTripSearch}
        pagination={{ ...pagination, count: tripsData?.total_count }}
        setActivitiesTimeRange={setActivitiesTimeRange}
        selectedId={selectedId}
      />
    </section>
  ) : (
    <QIErrorBoundary message={message} />
  );
};
